// axios
import axiosRequest from "util/axiosConfig";
// redux
import store from "redux/_store";

import {
  SET_LANGUAGE,
  SET_FAVOURITES,
  UPDATE_STUDENT,
  UPDATE_STUDENT_START,
} from "../types";
import { routes } from "util/constants";

export const setLanguage = (lang) => {
  store.dispatch((dispatch, getState) => {
    let { study_year, preferred_name } = getState().student;
    let { isAuthenticated } = getState().auth;

    // Updates language preference in store
    dispatch({
      type: SET_LANGUAGE,
      preferred_language: lang,
    });

    if (isAuthenticated) {
      axiosRequest
        .put(routes.studentUpdate, {
          preferred_language: lang,
          preferred_name,
          study_year,
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });
};

export const updateStudent = (student) => {
  console.log("studentUpdate: ", student);
  store.dispatch((dispatch) => {
    dispatch({
      type: UPDATE_STUDENT_START,
    });
    axiosRequest
      .put(routes.studentUpdate, {
        ...student,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_STUDENT,
          student,
        });
      })
      .catch(() => {
        // TODO: Could tell user it failed with toast
        dispatch({
          type: UPDATE_STUDENT,
        });
      });
  });
};

export const addFavourite = (favouriteItem, type) => {
  store.dispatch((dispatch, getState) => {
    const { student } = getState();
    const prevFavourites = student[type];

    let favourites = {};
    favourites[type] = [...prevFavourites, favouriteItem];

    // Add it to favourites in Redux Store
    dispatch({
      type: SET_FAVOURITES,
      favourites,
    });

    // Request to update the favourites by ids in database
    let fav_ids = favourites[type].map((item) => item.id);

    axiosRequest
      .put(routes.studentUpdateFav, {
        fav_ids,
        type,
      })
      .catch((error) => {
        //Remove Favourite in Redux Store
        console.log(error);
        favourites[type] = [
          ...favourites[type].filter(
            (checkId) => checkId.id !== favouriteItem.id
          ),
        ];
        dispatch({
          type: SET_FAVOURITES,
          favourites,
        });
        // TODO: Display Error to User
      });
  });
};

export const removeFavourite = (favouriteItem, type) => {
  store.dispatch((dispatch, getState) => {
    const { student } = getState();
    const prevFavourites = student[type];

    let favourites = {};
    favourites[type] = [
      ...prevFavourites.filter(
        (checkItem) => checkItem.id !== favouriteItem.id
      ),
    ];

    // Remove favourite from Redux Store
    dispatch({
      type: SET_FAVOURITES,
      favourites,
    });

    // Request to update the favourites by ids in database
    let fav_ids = favourites[type].map((item) => item.id);

    axiosRequest
      .put(routes.studentUpdateFav, {
        fav_ids,
        type,
      })
      .catch((error) => {
        //Add Favourite to Redux Store
        favourites[type] = [
          ...prevFavourites.filter(
            (checkItem) => checkItem.id !== favouriteItem.id
          ),
          favouriteItem,
        ];
        dispatch({
          type: SET_FAVOURITES,
          favourites,
        });
        // TODO: Display Error to User
      });
  });
};
