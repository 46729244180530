import React, { useState } from "react";

import { useLanguage } from "language/hook";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { favConstants } from "util/constants";
import {
  ItemDetails,
  ClubsDetails,
  ClubsSendToFriend,
  ClubsFavourite,
  ItemConfirm,
} from "./ItemComponents";

// Functionality commented thoroughly in Event.js
const ClubsResourcesL = ({
  isFavourite,
  content,
  isPopUp,
  besideFilters,
  fromFavourites,
}) => {
  const { title, description, additional_info } = content;
  const { itemStrings } = useLanguage();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleFavourite = () => {
    if (!isFavourite) addFavourite(content, favConstants.clubResources);
    else if (fromFavourites) setConfirmOpen(true);
    else removeFavourite(content, favConstants.clubResources);
  };

  const handleConfirmRemove = () => {
    removeFavourite(content, favConstants.clubResources);
    setConfirmOpen(false);
  };

  return (
    <div
      className={`item ${isPopUp ? "item--popup" : "item--list"} ${
        besideFilters ? "item--filters" : ""
      }`}
    >
      <ItemDetails
        title={title}
        description={description}
        additional_info={[additional_info]}
      />
      <div className="item__options">
        <ClubsSendToFriend
          content={content}
          title={itemStrings.clubResources}
        />
        {/*<ClubsFavourite isFavourite={isFavourite} handleFavourite={handleFavourite} />*/}
      </div>

      {confirmOpen && (
        <ItemConfirm
          setConfirmOpen={setConfirmOpen}
          handleConfirm={handleConfirmRemove}
        />
      )}
    </div>
  );
};

export default ClubsResourcesL;
