import React, {useRef, useState} from "react";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";
import {GoPlus} from "react-icons/go";

const ClubsFilters = ({ appliedFilters, handleFilter, inDrawer }) => {
  const { baseStrings } = useLanguage();

  const clubsTypes = useSelector((state) => state.data.clubsTypes);
  const clubsFaculty = useSelector((state) => state.data.clubsFaculty);

  const [setTypesActive, setTagsActiveState] = useState("");
  const [setTypesHeight, setTagsHeightState] = useState("0px")
  const [setTypesRotate, setTagsRotateState] = useState("")

  const [setFacultyActive, setFacultyActiveState] = useState("");
  const [setFacultyHeight, setFacultyHeightState] = useState("0px")
  const [setFacultyRotate, setFacultyRotateState] = useState("")

  const typesContent = useRef(null);
  const facultyContent = useRef(null);

  const toggleTypes = () => {
    setTagsActiveState(setTypesActive === "" ? "active" : "");
    setTagsHeightState(setTypesActive === "active" ? "0px" : `${typesContent.current.scrollHeight}px`);
    setTagsRotateState(setTypesActive === "active" ? "" : "rotate");
  }

  const toggleFaculty = () => {
    setFacultyActiveState(setFacultyActive === "" ? "active" : "");
    setFacultyHeightState(setFacultyActive === "active" ? "0px" : `${facultyContent.current.scrollHeight}px`);
    setFacultyRotateState(setFacultyActive === "active" ? "" : "rotate");
  }

  return (
    <div className={`filters ${inDrawer ? "filters--drawer" : ""}`}>
      <h1 className="filters__text">Filters</h1>
      <div className="filters__title">
        <button className={`filters__button ${setTypesActive}`} onClick={toggleTypes}>
          {baseStrings.types} <GoPlus className={`icon ${setTypesRotate}`} size="13px" />
        </button>
      </div>
      <div ref={typesContent} style={{maxHeight: `${setTypesHeight}`}} className="filters__group">
        {clubsTypes.map((filter, index) => {
          return (
            <div className="filter" key={index} onClick={() => handleFilter(filter.id, "types")}>
              <svg
                className={`filter__svg ${
                  appliedFilters["types"] && appliedFilters["types"].includes(filter.id) ? "filter__svg--filled" : ""
                }`}
              >
                <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />
                <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />
              </svg>
              <div className="filter__label">{filter.label}</div>
            </div>
          );
        })}
      </div>
      <div className="filters__title">
        <button className={`filters__button ${setFacultyActive}`} onClick={toggleFaculty}>
          {baseStrings.faculty} <GoPlus className={`icon ${setFacultyRotate}`} size="13px" />
        </button>
      </div>
      <div ref={facultyContent} style={{maxHeight: `${setFacultyHeight}`}} className="filters__group">
        {clubsFaculty.map((filter, index) => {
          return (
            <div className="filter" key={index} onClick={() => handleFilter(filter.id, "faculty")}>
              <svg
                className={`filter__svg ${
                  appliedFilters["faculty"] && appliedFilters["faculty"].includes(filter.id) ? "filter__svg--filled" : ""
                }`}
              >
                <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />
                <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />
              </svg>
              <div className="filter__label">{ filter.label }</div>
            </div>
          );
        })}
      </div>
         {/*{Object.keys(clubsFilters).map((key, index) => {*/}
    {/*//     return (*/}
    {/*//       <div className="filters__group" key={index}>*/}
    {/*//         <div className="filters__title">{baseStrings.clubsFilterNames[index]}</div>*/}
    {/*//         {clubsFilters[key].map((filter, index) => {*/}
    {/*//           return (*/}
    {/*//             <div className="filter" key={index} onClick={() => handleFilter(filter.id, key)}>*/}
    {/*//               <svg*/}
    {/*//                 className={`filter__svg ${*/}
    {/*//                   appliedFilters[key] && appliedFilters[key].includes(filter.id) ? "filter__svg--filled" : ""*/}
    {/*//                 }`}*/}
    {/*//               >*/}
    {/*//                 <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />*/}
    {/*//                 <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />*/}
    {/*//               </svg>*/}
    {/*//               <div className="filter__label">{filter.label}</div>*/}
    {/*//             </div>*/}
    {/*//           );*/}
    {/*//         })}*/}
    {/*//       </div>*/}
    {/*//     );*/}
    {/*//   })}*/}
    </div>
  );
};

export default ClubsFilters;
