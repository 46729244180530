import React, { useState } from "react";

import { useLanguage } from "language/hook";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { favConstants } from "util/constants";
import {ItemDetails, ItemLink, ItemSendToFriend, ItemFavourite, ItemConfirm, ClubsDetails} from "./ItemComponents";

// Functionality commented thoroughly in Event.js
const Resource = ({ isFavourite, content, isPopUp, besideFilters, fromFavourites }) => {
  const { title_en, title_fr, description_en, description_fr, link, address } = content;
  const { preferEnglish, itemStrings } = useLanguage();
  const [confirmOpen, setConfirmOpen] = useState(false);

  let title = preferEnglish ? title_en : title_fr;
  let description = preferEnglish ? description_en : description_fr;

  const handleFavourite = () => {
    if (!isFavourite) addFavourite(content, favConstants.resource);
    else if (fromFavourites) setConfirmOpen(true);
    else removeFavourite(content, favConstants.resource);
  };

  const handleConfirmRemove = () => {
    removeFavourite(content, favConstants.resource);
    setConfirmOpen(false);
  };

  return (
    <div className={`item ${isPopUp ? "item--popup" : "item--list"} ${besideFilters ? "item--filters" : ""}`}>
      <ItemDetails title={title} description={description} link={link} optionalNotes={[address]} />
      <div className="item__options">
        <ItemLink link={link} label={itemStrings.learnMore} />
        <ItemSendToFriend content={content} title={itemStrings.resource} />
        <ItemFavourite isFavourite={isFavourite} handleFavourite={handleFavourite} />
      </div>

      {confirmOpen && <ItemConfirm setConfirmOpen={setConfirmOpen} handleConfirm={handleConfirmRemove} />}
    </div>
  );
};

export default Resource;
