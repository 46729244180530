import React, { useState } from "react";
import ReactGA from "react-ga";

import "./Home.scss";

//Components
import Calender from "components/Calendar/Calendar";
import CustomModal from "components/CustomModal/CustomModal";
import Event from "components/Items/Event";
import sprites from "imgs/sprites.svg";

import moment from "moment";
import { useSelector } from "react-redux";
import { useLanguage } from "language/hook";
import { getTimeRange } from "./../../util/dataHelpers";

const Home = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const universityId = useSelector((state) => state.student.university.id);

  // filter events and links by university

  const rawLinks = useSelector((state) => state.data.links);
  const rawEventsToday = useSelector((state) => state.data.eventsToday);

  const links = rawLinks.filter((link) => {
    return link.university === universityId;
  });

  const eventsToday = rawEventsToday.filter((event) => {
    return event.university.id === universityId;
  });

  const { preferEnglish, baseStrings } = useLanguage();
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [eventTodayNum, setEventTodayNum] = useState(0);

  let todaysDate = moment().format("YYYY-MM-DD");

  const nextTodaysEvents = () => {
    setEventTodayNum(eventTodayNum + 3);
  };

  const prevTodaysEvents = () => {
    setEventTodayNum(eventTodayNum - 3);
  };

  const handleClose = () => {
    setSelectedEvent(null);
  };

  let sortedEventsToday = [...eventsToday];
  sortedEventsToday = sortedEventsToday.sort(
    (a, b) =>
      moment(a.start_time, "HH:mm:ss") - moment(b.start_time, "HH:mm:ss")
  );

  return (
    <div className="home">
      <div className="home-events">
        <svg
          className={`home-events__icon--prev ${
            eventTodayNum === 0 ? "home-events__icon--hidden" : ""
          }`}
          onClick={prevTodaysEvents}
        >
          <use href={sprites + "#icon-arrow"}></use>
        </svg>
        <div className="home-events__title heading-primary">
          {baseStrings.happeningToday}
        </div>
        <svg
          className={`home-events__icon--next ${
            eventTodayNum >= eventsToday.length - 3
              ? "home-events__icon--hidden"
              : ""
          }`}
          onClick={nextTodaysEvents}
        >
          <use href={sprites + "#icon-arrow"}></use>
        </svg>
        <div className="home-events__list">
          {sortedEventsToday.length > 0 ? (
            sortedEventsToday
              .slice(eventTodayNum, eventTodayNum + 3)
              .map((event, index) => {
                let locationString = baseStrings.locationFilters
                  .filter((location) => event.location.includes(location.value))
                  .map((location) => location.label)
                  .join(", ");
                return (
                  <div
                    className="home-event"
                    key={index}
                    onClick={() =>
                      setSelectedEvent({ ...event, date: todaysDate })
                    }
                  >
                    <div className="home-event__title text-secondary--bold">
                      {preferEnglish ? event.title_en : event.title_fr}
                    </div>
                    <div className="home-event__details">
                      <div className="home-event__location">
                        {locationString}
                      </div>
                      <div className="home-event__time text-tertiary">
                        {getTimeRange(event.start_time, event.end_time)}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="home-events__none-title heading-tertiary">
              {baseStrings.noEventsToday}
            </div>
          )}
        </div>
      </div>

      <div className="home-links">
        <div className="home__title heading-primary">
          {baseStrings.quickLinks}
        </div>
        {links.map((link, index) => {
          return (
            <a
              className="home-link text-secondary"
              key={index}
              href={
                preferEnglish
                  ? link.link
                  : link.link_en
                  ? link.link_fr
                  : link.link
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {preferEnglish ? link.title_en : link.title_fr}
            </a>
          );
        })}
      </div>
      <div className="home-calendar">
        <Calender />
      </div>

      {selectedEvent && (
        <CustomModal handleClose={handleClose}>
          <Event event={selectedEvent} key={selectedEvent.title} isPopUp />
        </CustomModal>
      )}
    </div>
  );
};

export default Home;
