import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//Google Analytic
import ReactGA from "react-ga";

//Image
import cpLogo from "imgs/navbar/ww-logo.png";
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";
import LanguageToggle from "components/LanguageToggle/LanguageToggle";

//axios
import axiosRequest from "util/axiosConfig";
import { useLanguage } from "language/hook";
import { routes, loginErrors } from "util/constants";

import { useInput } from "hooks";
import { sendAlert } from "redux/_actions/data";

const ResetPassword = () => {
  //Google Analystics
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { authStrings, utilStrings, preferEnglish } = useLanguage();
  const [loading, setLoading] = useState(false); // True when requests being made
  const [success, setSuccess] = useState(false); // True once a request is successful
  const [resString, setResString] = useState("");

  let { id, token } = useParams();

  const [password, bindPassword, resetPassword, passwordError, setPasswordError] = useInput("");
  const [conPass, bindConPass, resetConPass, conPassError, setConPassError] = useInput("");

  const getPasswordError = (error) => {
    if (error === loginErrors.mustNotBlank) return authStrings.mustNotBeBlank;
    else if (error === loginErrors.passTooShort) return authStrings.passTooShort;
    else if (error === loginErrors.needEightChar) return authStrings.passTooShort;
    else if (error === loginErrors.passTooCommon) return authStrings.passwordCommon;
    else if (error === loginErrors.passFieldsMatch) return authStrings.passwordsMustMatch;
    else return "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axiosRequest
      .post(routes.confirmResetPassword, {
        uid: id,
        token: token,
        new_password1: password,
        new_password2: conPass,
      })
      .then(() => {
        //If the user exist it will return Object. Otherwise, it will return []
        setResString(authStrings.resetSuccess);
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data) {
          console.log(err.response.data);
          if (err.response.data.token || err.response.data.uid) {
            setResString(authStrings.resetLinkExpire);
            setSuccess(true);
          } else {
            setPasswordError(
              err.response.data.new_password1 ? getPasswordError(err.response.data.new_password1[0]) : ""
            );
            setConPassError(
              err.response.data.new_password2 ? getPasswordError(err.response.data.new_password2[0]) : ""
            );
          }
        } else {
          sendAlert(utilStrings.serverError, "error");
        }
      });
  };

  let history = useHistory();
  const redirectToLogin = () => {
    resetPassword();
    resetConPass();
    history.push("/login");
  };

  return (
    <div className="onboarding">
      <LanguageToggle className="onboarding__lang-toggle button--blue" />

      <div className="onboarding__logo-container">
        <img src={cpLogo} alt={"logo"} />
        <img src={CP} alt={"logo"} />
      </div>
      {success ? (
        <div className="onboarding__success">
          <span className="onboarding__title">{resString}</span>
          <button className="onboarding__link" onClick={redirectToLogin}>
            {authStrings.clickToLogin}
          </button>
        </div>
      ) : (
        <div className="onboarding__content">
          <span className="onboarding__title">{authStrings.resetPassword}</span>
          <form className="onboarding__form" onSubmit={handleSubmit}>
            <div className="onboarding__inputs">
              <span className="onboarding__label">{authStrings.password}</span>
              <input
                className={`onboarding__input ${passwordError ? "onboarding__input--error" : ""}`}
                type="password"
                placeholder={authStrings.mustBeLonger}
                {...bindPassword}
              />
              <span className="onboarding__error">{passwordError}</span>

              <span className="onboarding__label">{authStrings.conPassword}</span>
              <input
                className={`onboarding__input ${conPassError ? "onboarding__input--error" : ""}`}
                type="password"
                placeholder={authStrings.conPassword}
                {...bindConPass}
              />
              <span className="onboarding__error">{conPassError}</span>
            </div>

            {loading ? (
              <div className="circle-button circle-button--loading">
                <Spinner color="#FFF" size="4rem" />
              </div>
            ) : (
              <input
                type="submit"
                className={`circle-button ${!preferEnglish ? "circle-button--small-text" : ""}`}
                value={authStrings.reset}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
