import React from "react";
import Filter from "./Filter";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

const BrowseFilters = ({ appliedFilters, handleFilter, inDrawer }) => {
  const { preferEnglish, baseStrings } = useLanguage();
  const resourceFilters = useSelector((state) => state.data.resourceFilters);

  return (
    <div className={`filters ${inDrawer ? "filters--drawer" : ""}`}>
      <div className="filters__group">
        <div className="filters__title">{baseStrings.language}</div>
        {baseStrings.languageFilters.map((filter, index) => {
          return (
            <Filter
              key={index}
              type="language"
              filter={filter}
              appliedFilters={appliedFilters}
              handleFilter={handleFilter}
            />
          );
        })}
      </div>

      <div className="filters__group">
        <div className="filters__title">{baseStrings.location}</div>
        {baseStrings.locationFilters.map((filter, index) => {
          return (
            <Filter
              key={index}
              type="location"
              filter={filter}
              appliedFilters={appliedFilters}
              handleFilter={handleFilter}
            />
          );
        })}
      </div>

      {Object.keys(resourceFilters).map((key, index) => {
        return (
          <div className="filters__group" key={index}>
            <div className="filters__title">{baseStrings.filterNames[index]}</div>
            {resourceFilters[key].map((filter, index) => {
              return (
                <div className="filter" key={index} onClick={() => handleFilter(filter.id, key)}>
                  <svg
                    className={`filter__svg ${
                      appliedFilters[key] && appliedFilters[key].includes(filter.id) ? "filter__svg--filled" : ""
                    }`}
                  >
                    <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />
                    <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />
                  </svg>
                  <div className="filter__label">{preferEnglish ? filter.label_en : filter.label_fr}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default BrowseFilters;
