import {
  SET_LINKS,
  SET_MESSAGE,
  SET_EVENTS_TAGS,
  SET_EVENTS_FACULTY,
  SET_ONDEMAND_TAGS,
  SET_RESOURCE_FILTERS,
  SET_CLUBS_TYPES,
  SET_CLUBS_FACULTY,
  SET_CLUBS_RESOURCES_FILTERS,
  SET_EVENTS_TODAY,
  SET_ALERT,
  CLEAR_ALERT_TIMER,
  HIDE_ALERT,
} from "../types.js";

const initialState = {
  links: [],
  linksSet: false,

  messageOfTheDay: {
    message_en: "how are you currently feeling?",
    message_fr: "comment vous sentez-vous actuellement ?",
  },
  messageSet: false,

  resourceFilters: { tags: [], method: [], group: [] },
  resourceFiltersSet: false,

  clubsFilters: { types: [], faculty: [] },
  clubsFiltersSet: false,

  clubsTypes: [],

  clubsTypesSet: false,

  clubsFaculty: [],
  clubsFacultySet: false,

  clubsResourcesFilters: { types: [] },
  clubsResourcesSet: false,

  eventTags: [],
  eventTagsSet: false,

  eventFaculty: [],
  eventFacultySet: false,

  onDemandTags: [],
  onDemandTagsSet: false,

  eventsToday: [],
  eventsTodaySet: false,
  libraryFilters: [],

  alert: {
    text: "",
    type: "",
    visible: false,
    timer: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LINKS:
      return {
        ...state,
        links: action.links,
        linksSet: true,
      };
    case SET_MESSAGE:
      return {
        ...state,
        messageOfTheDay: action.message,
        messageSet: true,
      };
    case SET_EVENTS_TAGS:
      return {
        ...state,
        eventTags: action.tags,
        eventTagsSet: true,
      };
    case SET_EVENTS_FACULTY:
      return {
        ...state,
        eventFaculty: action.faculty,
        eventFacultySet: true,
      };
    case SET_EVENTS_TODAY:
      return {
        ...state,
        eventsToday: action.events,
        eventsTodaySet: true,
      };
    case SET_ONDEMAND_TAGS:
      return {
        ...state,
        onDemandTags: action.tags,
        onDemandTagsSet: true,
      };
    case SET_RESOURCE_FILTERS:
      return {
        ...state,
        resourceFilters: action.filters,
        resourceFiltersSet: true,
      };
    case SET_CLUBS_TYPES:
      return {
        ...state,
        clubsTypes: action.types,
        clubsTypesSet: true,
      };
    case SET_CLUBS_FACULTY:
      return {
        ...state,
        clubsFaculty: action.faculty,
        clubsFacultySet: true,
      };
    case SET_CLUBS_RESOURCES_FILTERS:
      return {
        ...state,
        clubsResourcesFilters: action.filters,
        clubsResourcesFiltersSet: true,
      };
    case SET_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    case CLEAR_ALERT_TIMER:
      return {
        ...state,
        alert: {
          ...state.alert,
          timer: null,
        },
      };
    case HIDE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          visible: false,
        },
      };
    default:
      return state;
  }
}
