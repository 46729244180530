import React from "react";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

const LibraryFilters = ({ appliedFilters, handleFilter, inDrawer }) => {
  const { preferEnglish, baseStrings } = useLanguage();
  const libraryFilters = useSelector((state) => state.data.libraryFilters);
  console.log("libraryFilters", libraryFilters);

  return (
    <div className={`filters ${inDrawer ? "filters--drawer" : ""}`}>
      {Object.keys(libraryFilters).map((key, index) => {
        return (
          <div className="filters__group" key={index}>
            <div className="filters__title">
              {baseStrings.libraryFilterNames[index]}
            </div>
            {libraryFilters[key].map((filter, index) => {
              return (
                <div
                  className="filter"
                  key={index}
                  onClick={() => handleFilter(filter.id, key)}
                >
                  <svg
                    className={`filter__svg ${
                      appliedFilters[key] &&
                      appliedFilters[key].includes(filter.id)
                        ? "filter__svg--filled"
                        : ""
                    }`}
                  >
                    <circle
                      className="filter__svg-border"
                      cx="11"
                      cy="11"
                      r="9.6"
                      fill="none"
                    />
                    <circle
                      className="filter__svg-fill"
                      cx="11"
                      cy="11"
                      r="9.3"
                      stroke="white"
                      fill="white"
                    />
                  </svg>
                  <div className="filter__label">
                    {preferEnglish ? filter.label_en : filter.label_fr}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default LibraryFilters;
