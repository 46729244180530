const getAuthTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        register: "Register",
        pleaseRegister: "Please Register",
        name: "Name",
        email: "Email",
        yearOfStudy: "Year of Study",
        password: "Password",
        conPassword: "Confirm Password",
        alreadyAccount: "Already have an account?",
        login: "Login",
        backToLogin: "Back to Login Page?",
        send: "Send",
        notYetSignedUp: "Not yet signed up?",
        enter: "Enter",
        enterEmail: "Enter Your University Email",
        enterPassword: "Enter Your Password",
        preferredName: "Preferred Name",
        yourPreferredName: "Your Preferred Name",
        forgotPassword: "Forgot Password",
        emailNotFound: "Could not find email",
        yourHolisticPlatform: "Living Your Best Student Life Starts Here",

        university: "University",
        save: "Save",
        saved: "Saved",
        saving: "Saving",

        selectYear: "Select Year",
        firstYear: "First Year",
        secondYear: "Second Year",
        thirdYear: "Third Year",
        fourthYear: "Fourth Year",
        fifthYear: "Fifth Year +",

        type_study1: "Type of Study",
        Study1: "Undergraduate",
        Study2: "Graduate",
        Study3: "Continuing Education",
        Study4: "Professional Development",

        type_student: "Type of Student",
        Student1: "Domestic",
        Student2: "International",
        Student3: "Exchange ",

        welcome: (name) => `Welcome ${name}`,
        youreAlmostThere: (name) => `You're almost there, ${name}!`,
        welcomeBack: "Welcome Back",
        thanksRegister: "Thank you for registering with us",
        checkEmail: (email) =>
          `Final step, check ${email} to verify your email address before logging in!`,
        clickToLogin: "Click to go to login",
        success: "Success",
        reset: "Reset",
        resetPassword: "Reset Password",
        resetSuccess: "Reset Password Successful",
        resetLinkExpire: "Reset Password Link has Expired",
        activating: "Activating Account",
        activatingError: "Error While Activating User",
        activatingSuccess: "User Sucessfully Activated",
        activatingInvalid: "Activation Link is Invalid",

        // Password Errors
        mustBeLonger: "Must be 8-30 Characters",
        mustNotBeBlank: "This field may not be blank",
        passTooShort:
          "This password is too short.It must contain at least 8 characters.",
        enterValidEmail: "Please enter a valid email address",
        emailTaken: "Email Already Taken by Another User",
        useSchoolEmail: "Please use your school email to register",
        passwordCommon: "This password is too common.",
        passwordsMustMatch: "Passwords must match",
        loginIncorrect: "Email or Password is incorrect. Please try again",
        checkIfVerified:
          "Please check if you've verified your account by checking your email",
      }
    : {
        register: "S'inscrire",
        pleaseRegister: "Veuillez vous inscrire",
        name: "Nom",
        email: "Courriel",
        yearOfStudy: "Niveau d'étude",
        password: "Mot de passe",
        conPassword: "Confirmer le mot de passe",
        alreadyAccount: "Tu as déjà un compte?",
        login: "Connexion",
        backtoLogin: "Retour à la page d'accueil",
        send: "Envoyer",
        notYetSignedUp: "Pas encore inscrit(e)?",
        enter: "Entrez",
        enterEmail: "Entrez votre courriel de l'Université",
        enterPassword: "Entrez votre mot de passe",
        preferredName: "Nom préféré",
        yourPreferredName: "Nom préféré",
        forgotPassword: "J'ai oublié mon mot de passe",
        emailNotFound: "Impossible de trouver le courriel",
        yourHolisticPlatform: "votre plateforme holistique de vie étudiante",

        selectYear: "Selectionner l'année",
        firstYear: "Première année",
        secondYear: "Deuxième année",
        thirdYear: "Troisième année",
        fourthYear: "Quatrième année",
        fifthYear: "Cinquième année et autre",

        university: "Université",
        save: "Sauvegarder",
        saved: "Sauvegardé",
        saving: "Sauvegarder",

        type_study1: "Type d'étude",
        Study1: "Premier cycle",
        Study2: "Diplômée",
        Study3: "Formation continue",
        Study4: "Développement professionnel",

        type_student: "Type d'étudiant",
        Student1: "Nationale",
        Student2: "Internationale",
        Student3: "Échanger",

        welcome: (name) => `Bienvenue ${name}`,
        youreAlmostThere: (name) => `Vous y êtes presque, ${name}!`,
        welcomeBack: "Bienvenue",
        thanksRegister: "Merci de vous enregistrer auprès de nous",
        checkEmail: (email) =>
          `Verifiez votre courriel pour activer le compte: ${email}`,
        clickToLogin: "Cliquez pour vous connecter",
        success: "Succès",

        reset: "Réinitialiser",
        resetPassword: "Réinitialiser le mot de passe",
        resetSuccess: "Succès: mot de passe réinitialisé",
        resetLinkExpire: "Le lien de réinitialisation du mot de passe a expiré",
        activating: "Activation du compte",
        activatingError: "Erreur lors de l'activation de l'utilisateur",
        activatingSuccess: "Utilisateur activé avec succès",
        activatingInvalid: "Le lien d'activation n'est pas valide",

        // Password Errors
        mustBeLonger: "Doit être 8 à 30 caractères",
        mustNotBeBlank: "Ce champ ne peut pas être vide",
        passTooShort:
          "Ce mot de passe est trop court. Il doit contenir au moins 8 caractères",
        enterValidEmail: "Veuillez entrer un courriel valide",
        emailTaken: "Adresse courriel déjà utilisée par un autre utilisateur",
        useSchoolEmail:
          "Veuillez utiliser le courriel de votre institution pour vous inscrire",
        passwordCommon: "Ce mot de passe est trop simple",
        passwordsMustMatch: "Les mots de passe doivent correspondre",
        loginIncorrect:
          "Le courriel ou le mot de passe est incorrect. Veuillez réessayer.",
        checkIfVerified:
          "Veuillez vérifier si vous avez vérifié votre compte en vérifiant votre e-mail",
      };
};

export default getAuthTranslations;
