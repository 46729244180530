import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

import axiosRequest from "util/axiosConfig";

import BrowseFilters from "components/Filters/BrowseFilters";
import Resource from "components/Items/Resource";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import Spinner from "components/Spinner/Spinner";
import { routes } from "util/constants";

const Browse = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [resources, setResources] = useState({ data: [], loading: true });
  const [appliedFilters, setAppliedFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const favourites = useSelector((state) => state.student.fav_resource);
  const universityId = useSelector((state) => state.student.university.id);
  const { baseStrings } = useLanguage();

  useEffect(() => {
    setResources({ data: [], loading: true });

    let filteredUrl = "";
    for (let filterType in appliedFilters) {
      for (let filter of appliedFilters[filterType]) {
        filteredUrl += `${filterType}=${filter}&`;
      }
    }

    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.resources(filteredUrl))
        .then((response) => {
          if (!isCancelled) {
            // filter events by university
            let filteredResources = response.data.filter((resource) => {
              return resource.university.id === universityId;
            });

            setResources({ data: filteredResources, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [appliedFilters]);

  const handleFilter = (newId, key) => {
    let newAppliedFilters = { ...appliedFilters };
    if (newAppliedFilters[key]) {
      if (newAppliedFilters[key].includes(newId)) {
        newAppliedFilters[key] = newAppliedFilters[key].filter(
          (id) => id !== newId
        );
      } else {
        newAppliedFilters[key] = [...newAppliedFilters[key], newId];
      }
    } else {
      newAppliedFilters[key] = [newId];
    }
    setAppliedFilters(newAppliedFilters);
  };

  return (
    <div className="list-page list-page--filters">
      <CustomDrawer
        visible={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
      >
        <BrowseFilters
          inDrawer
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
        />
      </CustomDrawer>

      <div className="list-page__filters">
        <BrowseFilters
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
        />
      </div>

      <div className="list-page__content">
        <div className="list-page__title">{baseStrings.browseResources}</div>
        <button
          className="list-page__filter-button button--blue"
          onClick={() => setDrawerOpen(true)}
        >
          {baseStrings.filters}
        </button>

        <div className="list-page__list">
          {resources.loading && (
            <div className="list-page__loading" id="loading">
              <Spinner />
            </div>
          )}

          {resources.data.map((resource) => (
            <Resource
              content={resource}
              key={resource.id}
              isFavourite={favourites
                .map((item) => item.id)
                .includes(resource.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Browse;
