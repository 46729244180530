import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//Google Analytic
import ReactGA from "react-ga";

//Image
import cpLogo from "imgs/navbar/ww-logo.png";
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";
import LanguageToggle from "components/LanguageToggle/LanguageToggle";

//axios
import axiosRequest from "util/axiosConfig";

import { useInput } from "hooks";
import { routes } from "util/constants";
import { useLanguage } from "language/hook";
import { sendAlert } from "redux/_actions/data";

const ForgotPassword = () => {
  //Google Analystics
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { authStrings, utilStrings } = useLanguage();
  const [loading, setLoading] = useState(false); // True when requests being made
  const [success, setSuccess] = useState(false); // True once a request is successful
  const [email, bindEmail, resetEmail, emailError, setEmailError] = useInput("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axiosRequest
      .get(routes.findUser(email.toLowerCase()))
      .then((res) => {
        //If the user exist it will return Object. Otherwise, it will return []
        if (!res.data[0]) {
          emailNotFound();
        } else {
          axiosRequest
            .post(routes.resetPassword, {
              email: email.toLowerCase(),
            })
            .then(() => {
              setLoading(false);
              setSuccess(true);
            })
            .catch(() => {
              // If email could not be sent
              sendAlert(utilStrings.serverError, "error");
              setLoading(false);
            });
        }
      })
      .catch(() => {
        emailNotFound();
      });
  };

  const emailNotFound = () => {
    setLoading(false);
    setEmailError(authStrings.emailNotFound);
  };

  let history = useHistory();
  const redirectToLogin = () => {
    resetEmail();
    history.push("/login");
  };

  return (
    <div className="onboarding">
      <LanguageToggle className="onboarding__lang-toggle button--blue" />

      <div className="onboarding__logo-container">
        <img src={cpLogo} alt={"logo"} />
        <img src={CP} alt={"logo"} />
      </div>
      {success ? (
        <div className="onboarding__success">
          <span className="onboarding__title">{authStrings.success}</span>
          <span className="onboarding__info">{authStrings.checkEmail(email)}</span>
          <button className="onboarding__link" onClick={redirectToLogin}>
            {authStrings.clickToLogin}
          </button>
        </div>
      ) : (
        <div className="onboarding__content">
          <span className="onboarding__title">{authStrings.forgotPassword}</span>
          <form className="onboarding__form" onSubmit={handleSubmit}>
            <div className="onboarding__inputs">
              <span className="onboarding__label">{authStrings.email}</span>
              <input
                name="email"
                className={`onboarding__input ${emailError ? "onboarding__input--error" : ""}`}
                type="text"
                placeholder={authStrings.enterEmail}
                autoComplete="on"
                {...bindEmail}
              />
              <span className="onboarding__error">{emailError}</span>
            </div>

            <div className="onboarding__redirect">
              {authStrings.backToLogin}&nbsp;&nbsp;
              <button type="button" onClick={redirectToLogin}>
                {authStrings.login}
              </button>
            </div>

            {loading ? (
              <div className="circle-button circle-button--loading">
                <Spinner color="#FFF" size="4rem" />
              </div>
            ) : (
              <input type="submit" className="circle-button" value={authStrings.send} />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
