import React from "react";
import ReactGA from "react-ga";

import { useSelector } from "react-redux";

import "./Favourites.scss";

import Resource from "components/Items/Resource";
import Clubs from "components/Items/ClubsList";
import LiveStreamClass from "components/Items/LiveStreamClass";
import OnCampusClass from "components/Items/OnCampusClass";
import MindfulnessExercise from "components/Items/MindfulnessExercise";
import OnDemandClass from "components/Items/OnDemandClass";
import Event from "components/Items/Event";
import ClubsResources from "components/Items/ClubsResourceList";

import { useLanguage } from "language/hook";

import { Tabs } from "antd";

const { TabPane } = Tabs;

const Favourites = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const universityName = useSelector(state => state.student.university.name)

  const { fav_mindfulness, fav_resource, fav_livestream, fav_oncampus, fav_ondemand, fav_event, fav_clubs, fav_clubsResources } = useSelector(
    (state) => state.student
  );

  const { baseStrings, itemStrings } = useLanguage();

  return (
    <div className="favourites">
      <div className="favourites__title heading-secondary">{baseStrings.myFavourites}</div>

      <Tabs defaultActiveKey="1">
        <TabPane tab={itemStrings.event} key="1">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_event.map((event, index) => (
                  <Event event={event} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={itemStrings.resource} key="2">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_resource.map((resource, index) => (
                  <Resource content={resource} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={itemStrings.mindfulnessResource} key="3">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_mindfulness.map((resource, index) => (
                  <MindfulnessExercise content={resource} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={itemStrings.liveStreamClass} key="4">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_livestream.map((resource, index) => (
                  <LiveStreamClass content={resource} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={itemStrings.onDemandClass} key="5">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_ondemand.map((resource, index) => (
                  <OnDemandClass content={resource} isFavourite={true} key={index} hasImage fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={itemStrings.onCampusClass} key="6">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_oncampus.map((resource, index) => (
                  <OnCampusClass content={resource} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={universityName === "Queens" ? itemStrings.clubs : ""} key="7">
          <div className="list-page">
            <div className="list-page__content">
              <div className="list-page__list">
                {fav_clubs.map((clubs, index) => (
                  <Clubs content={clubs} isFavourite={true} key={index} fromFavourites />
                ))}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={universityName === "Queens" ? itemStrings.amsResources : ""} key="8">
        <div className="list-page">
          <div className="list-page__content">
            <div className="list-page__list">
              {fav_clubsResources.map((resources, index) => (
                <ClubsResources content={resources} isFavourite={true} key={index} fromFavourites />
              ))}
            </div>
          </div>
        </div>
      </TabPane> 
      </Tabs>
    </div>
  );
};

export default Favourites;