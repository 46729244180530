import React, { useEffect } from "react";
import { setData } from "redux/_actions/data";

//Image
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";

const LoadingPage = ({ isLoginPage }) => {
  useEffect(() => {
    if (!isLoginPage) setData();
  }, [isLoginPage]);

  return (
    <div className="loading-page">
      <img src={CP} alt={"logo"} />
      <Spinner size="4rem" />
    </div>
  );
};

export default LoadingPage;
