const getUtilTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        serverError: "Server Error",
      }
    : {
        serverError: "Erreur du serveur",
      };
};

export default getUtilTranslations;
