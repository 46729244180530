const getBannerTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        fitnessFooter:
          "Every body and every mind requires varying levels of physical movement - exercise should be practiced in moderation.\nWe encourage you to listen to your physical and psychological needs to determine the appropriate type and amount of exercise for you.",
        mentalHealthFooter:
          "These are tools to support general mental wellbeing, but are not a substitute for professional mental health services.\nPlease refer to the emergency support button for immediate and acute mental health support or visit your physician.",
        contactUsAt: "Contact us at",
        checkPrivacyPolicy: "Check out our Privacy Policy",
      }
    : {
        fitnessFooter:
          "Chaque corps et chaque âme exige des niveaux variables de mouvement physique - l'exercice doit être pratiqué avec modération.\nNous vous encourageons à écouter vos besoins physiques et psychologiques afin de déterminer le type et la quantité d'exercice qui vous conviennent.",
        mentalHealthFooter:
          "Ce sont des outils destinés à favoriser le bien-être mental général, mais ils ne remplacent pas les services professionnels de santé mentale.\nVeuillez vous référer au bouton d'aide d'urgence pour obtenir une aide immédiate et pointue en matière de santé mentale ou consultez votre médecin.",
        contactUsAt: "Contactez nous à  ",
        checkPrivacyPolicy: "Consultez notre Politique de confidentialité",
      };
};

export default getBannerTranslations;
