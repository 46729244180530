import React from "react";

import "./Emergency.scss";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

const Emergency = () => {
  const { preferEnglish } = useLanguage();
  const universityName = useSelector((state) => state.student.university.name);

  if (universityName === "Queens") {
    return (
      <>
        <div className="emergency">
          <div className="emergency__item">
            <div className="emergency__desc">
              If you are in need of mental health emergency support, mental
              health acute intervention, or are concerned about someone else’s
              mental health i.e. harming themselves or others, please use the
              following resources:
            </div>
          </div>
          <div className="emergency__item">
            <a
              href="https://good2talk.ca/ontario/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="emergency__title">Good2Talk</div>
            </a>
            <div className="emergency__desc">
              Call{" "}
              <a
                href="tel:+1-866-925-5454"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>1-866-925-5454</strong>
              </a>{" "}
              or text <strong>GOOD2TALKON</strong> to{" "}
              <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
                <strong>686868</strong>
              </a>
              <br />
              <em>
                Good2Talk is a free, confidential service for post-secondary
                students in Ontario, available 24/7/365. Post-secondary students
                in Ontario can receive professional counselling and information
                and referrals for mental health, addictions and well-being.
              </em>
            </div>
          </div>
          <div className="emergency__item">
            <a
              href="https://www.crisisservicescanada.ca/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <div className="emergency__title">Crisis Text Line</div>
            </a>{" "}
            <div className="emergency__desc">
              Text <strong>HOME</strong> to{" "}
              <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
                <strong>686868</strong>
              </a>{" "}
              from anywhere in Canada. <br />
              <em>
                Crisis Services Canada (CSC) is a national network of existing
                distress, crisis and suicide prevention line services. The
                organization is committed to supporting any person living in
                Canada who is affected by suicide, in the most caring and least
                intrusive manner possible.
              </em>
            </div>
          </div>

          <div className="emergency__item">
            <div className="emergency__title">
              Protection Services (on campus)
            </div>
            <div className="emergency__desc">
              <a
                href="tel:+613-562-5411"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>613-562-5411</strong>
              </a>
            </div>
          </div>

          <div className="emergency__item">
            <div className="emergency__title">
              Emergency Response (off-campus)
            </div>
            <div className="emergency__desc">
              <strong>9-1-1</strong>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="emergency">
        {preferEnglish ? (
          <>
            <div className="emergency__item">
              <div className="emergency__desc">
                If you are in need of mental health emergency support, mental
                health acute intervention, or are concerned about someone else’s
                mental health i.e. harming themselves or others, please use the
                following resources:
              </div>
            </div>
            <div className="emergency__item">
              <a
                href="https://good2talk.ca/ontario/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="emergency__title">Good2Talk</div>
              </a>
              <div className="emergency__desc">
                Call{" "}
                <a
                  href="tel:+1-866-925-5454"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>1-866-925-5454</strong>
                </a>{" "}
                or text <strong>GOOD2TALKON</strong> to{" "}
                <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
                  <strong>686868</strong>
                </a>
                <br />
                <em>
                  Good2Talk is a free, confidential service for post-secondary
                  students in Ontario, available 24/7/365. Post-secondary
                  students in Ontario can receive professional counselling and
                  information and referrals for mental health, addictions and
                  well-being.
                </em>
              </div>
            </div>
            <div className="emergency__item">
              <a
                href="https://www.crisisservicescanada.ca/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <div className="emergency__title">Crisis Text Line</div>
              </a>{" "}
              <div className="emergency__desc">
                Text <strong>HOME</strong> to{" "}
                <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
                  <strong>686868</strong>
                </a>{" "}
                from anywhere in Canada. <br />
                <em>
                  Crisis Services Canada (CSC) is a national network of existing
                  distress, crisis and suicide prevention line services. The
                  organization is committed to supporting any person living in
                  Canada who is affected by suicide, in the most caring and
                  least intrusive manner possible.
                </em>
              </div>
            </div>

            <div className="emergency__item">
              <div className="emergency__title">
                Protection Services (on campus)
              </div>
              <div className="emergency__desc">
                <a
                  href="tel:+613-562-5411"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>613-562-5411</strong>
                </a>
              </div>
            </div>

            <div className="emergency__item">
              <div className="emergency__title">
                Emergency Response (off-campus)
              </div>
              <div className="emergency__desc">
                <strong>9-1-1</strong>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="emergency__item">
              <div className="emergency__desc">
                Si tu as besoin d'une aide d'urgence en santé mentale, d'une
                intervention rapide en santé mentale ou si tu es préoccupé(e)
                par la santé mentale d'une autre personne, c'est-à-dire si tu te
                fais du mal ou si tu fais du mal à autrui, utilise les
                ressources suivantes:
              </div>
            </div>
            <div className="emergency__item">
              <a
                href="https://allojecoute.ca/ontario/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="emergency__title">Allo J’écoute</div>
              </a>
              <div className="emergency__desc">
                Appelez au{" "}
                <a
                  href="tel:+1-866-925-5454"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>1-866-925-5454</strong>
                </a>{" "}
                ou textez <strong>ALLOJECOUTEON</strong> au{" "}
                <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
                  <strong>686868</strong>
                </a>
                <br />
                <em>
                  Allo J’écoute est un service d’aide par texto confidentiel,
                  accessible gratuitement 24 heures par jour, 7 jours par
                  semaine, à longueur d’année. Les étudiant(e)s de niveau
                  postsecondaire en Ontario n’ont qu’à texter ALLOJECOUTEON au
                  686868 pour échanger avec un Répondant aux crises bénévole
                  dûment formé qui est à l’écoute et pour offrir du soutien aux
                  jeunes en besoin.
                </em>
              </div>
            </div>
            <div className="emergency__item">
              <a
                href="https://www.crisisservicescanada.ca/fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="emergency__title">
                  Le Service de prévention du suicide du Canada
                </div>
              </a>{" "}
              <div className="emergency__desc">
                Appelez au{" "}
                <a
                  href="tel:+1-866-456-4566"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>1-866-456-4566</strong>
                </a>
                <br />
                (Pour les residents du Québec, appelez au{" "}
                <a
                  href="tel:+1-866-277-3553"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>1-866-277-3553</strong>
                </a>
                )<br />
                <em>
                  Si vous pensez au suicide, vous êtes inquiet(e) d’un(e) ami(e)
                  ou d’un être cher, le Service de prévention du suicide du
                  Canada est disponible 24 heures par jour, 7 jours par semaine
                  par téléphone. L’organisation visa à supporter toute personne
                  qui habite au Canada et qui est touchée par le suicide, de la
                  manière la plus attentionnée et la moins intrusive possible.
                </em>
              </div>
            </div>

            <div className="emergency__item">
              <div className="emergency__title">
                Service de la protection (sur le campus)
              </div>
              <div className="emergency__desc">
                <a
                  href="tel:+613-562-5411"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>613-562-5411</strong>
                </a>
              </div>
            </div>

            <div className="emergency__item">
              <div className="emergency__title">Intervention d'urgence</div>
              <div className="emergency__desc">
                <strong>9-1-1</strong>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default Emergency;
