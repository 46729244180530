import React, { Component } from "react";
import QuizStart from './QuizStart';
import Terms from "./Terms&Conditions";
import SurveyModel from "./Survey";
import Success from "./Success";
import {Box, Paper, Container} from "@material-ui/core";
import "survey-react/modern.css";
// import ProgressBar from "./ProgressBar";

export class UserForm extends Component {

    state = {
        step: 1,
        firstName: '',
    }
    //Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    //Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    }

    //Handle fields change
    handleChange = input  => e => {
        this.setState({[input]: e.target.value});
    }


    render() {
        const { step } = this.state;
        const {firstName} = this.state;
        const values = { firstName };

        switch(step) {
            case 1:
                return (
                    <QuizStart
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        />
                );
            case 2:
                return (
                    <Box display = "flex" justifyContent = "center" alignItems = "center" minHeight = "75vh" minWidth="75vh">
                        <Terms
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}/>
                    </Box>
                );
            case 3:
                return (
                   <Box display = "flex" justifyContent = "center" alignItems = "center" minHeight="100vh">
                       <Container maxWidth="md">
                            <SurveyModel
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChange}
                                />
                       </Container>
                   </Box>
                );

            case 4:
                return (
                    <Success />
                );
        }
    }
}
export default UserForm