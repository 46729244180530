import React from "react";
import { Link } from "react-router-dom";

import backgroundBlue from "imgs/mental-health/background-blue.svg";
import backgroundGrey from "imgs/mental-health/background-grey.svg";
import articles from "imgs/mental-health/articles.png";
import scale from "imgs/mental-health/scale.png";

import cpLogo from "imgs/navbar/ww-logo.png";

import "./MentalHealth.scss";

import ReactGA from "react-ga";
import { useLanguage } from "language/hook";

const MentalHealth = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { baseStrings, bannerStrings } = useLanguage();

  // temporary fix to quiz while Mahir works on actual

  const tabs = [
    {
      title: baseStrings.myRecommendedResources,
      description: baseStrings.recommendedHeading,
      button: baseStrings.start,
      back: backgroundBlue,
      icon: cpLogo,
      link: "https://intakeq.com/new/AueuHj",
      linksOut: true,
    },
    {
      title: baseStrings.browseResources,
      description: baseStrings.browseHeading,
      button: baseStrings.viewAll,
      icon: articles,
      link: "mental-health/all-resource",
    },
    {
      title: baseStrings.mindfulnessResources,
      description: baseStrings.mindfulnessHeading,
      button: baseStrings.viewAll,
      back: backgroundGrey,
      icon: scale,
      link: "mental-health/mindfulness-resource",
    },
  ];

  return (
    <div className="mental-health">
      {tabs.map((tab, index) => (
        <div
          className="tab-container"
          style={{ backgroundImage: `url(${tab.back})` }}
          key={index}
        >
          <div className="tab__info">
            <img className="tab__img" src={tab.icon} alt={tab.title} />
            <div className="tab__title heading-secondary">{tab.title}</div>
            <div className="tab__description">{tab.description}</div>
          </div>
          {tab.linksOut ? (
            <a href={tab.link} target="_blank" rel="noopener noreferrer">
              <button className="tab__button">{tab.button}</button>
            </a>
          ) : (
            <Link to={tab.link}>
              <button className="tab__button">{tab.button}</button>
            </Link>
          )}
        </div>
      ))}
      <div className="mental-health__banner">
        <span className="text-banner">{bannerStrings.mentalHealthFooter}</span>
      </div>
    </div>
  );
};

export default MentalHealth;
