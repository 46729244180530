import React, {useState, Component, useEffect} from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {createMuiTheme, Typography, ThemeProvider, Box} from "@material-ui/core";
import RaisedButton from 'material-ui/RaisedButton';
import logo from "./QuizIcons/helpline.png"
import {Button} from "@material-ui/core"

//Components
import { useSelector } from "react-redux";
import "./QuizStart.scss"
import {blue, blueGrey, grey, lightBlue, red} from "@material-ui/core/colors";

const theme = createMuiTheme({
    typography: {
        h1: {
            fontSize: 100,
            fontWeight: 100,
        }
    }
})

export class QuizStart extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    render() {
        return(
            <Box display = "flex" justifyContent = "center" alignItems = "center" minHeight = "75vh">
            <MuiThemeProvider>
                <div className = "QuizStartPage">
                        <h1>Hey Mahir!</h1>
                    <br/>
                    <div class = "container">
                        <img src = {logo} alt = "Logo"/>
                    </div>
                    <br/>
                    {/*<Typography>*/}
                        <h1>Thanks for taking the time to prioritize your wellness!</h1>
                        <br/>
                        <h1>We recognize that your unique needs, wants, and </h1>
                        <h1>identity influence your wellness...</h1>
                    {/*</Typography>*/}
                    <br/>
                    <Button variant="outlined" style={{borderRadius: 40, backgroundColor: "#ffffff", borderColor: "#8ad0de", borderWidth: 2, padding: "9px 40px", fontSize: "18px"}} onClick={this.continue}> Begin </Button>
                </div>
            </MuiThemeProvider>
            </Box>
        );
    }
}

export default QuizStart