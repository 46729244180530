import React, { useEffect, useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";

import cpLogoName from "imgs/navbar/master-logo.png";
import unionLogo from "imgs/navbar/union-logo.png";
import profile from "imgs/navbar/profile.png";

import "./NavBar.scss";
import sprites from "imgs/sprites.svg";

import Emergency from "./Emergency/Emergency";
import Profile from "./Profile/Profile";

import LanguageToggle from "./../LanguageToggle/LanguageToggle";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { logout } from "redux/_actions/auth";
import { useLanguage } from "language/hook";

import CustomModal from "./../CustomModal/CustomModal";
import CustomDrawer from "./../CustomDrawer/CustomDrawer";
import { useSelector } from "react-redux";
import { useInput } from "hooks";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const student = useSelector((state) => state.student);
  const universityName = useSelector((state) => state.student.university.name);
  const [preferred_name, bindName] = useInput(student.preferred_name);
  const [study_year, bindYear] = useInput(student.study_year);
  const [student_type, bindStudentType] = useInput(student.type_of_student);
  const [study_type, bindStudyType] = useInput(student.type_of_study);
  const { baseStrings } = useLanguage();

  const [profileOpen, setProfileOpen] = useState(false);
  const [emergencyOpen, setEmergencyOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (
      !student.university.name ||
      !student.email ||
      !preferred_name ||
      !bindName.value ||
      !study_year ||
      !bindYear.value ||
      !bindStudyType.value ||
      !bindStudentType.value ||
      !study_type ||
      !student_type
    ) {
      setProfileOpen(true);
    }
  }, [student]);

  // French translations done by Ben via Google, MUST be replaced
  const tabs = [
    {
      path: "/home",
      text: baseStrings.myCommunity,
    },
    {
      path: "/mental-health",
      text: baseStrings.myMentalHealth,
    },
    {
      path: "/fitness",
      text: baseStrings.myPhysicalHealth,
    },
  ];

  if (universityName === "Queens") {
    tabs.push({
      path: "/ams-clubs",
      text: baseStrings.myClubs,
    });
  }

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const CustomMenu = () => {
    return (
      <Menu
        className="nav-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            setProfileOpen(true);
          }}
        >
          {baseStrings.profile}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            history.push("/favourites");
          }}
        >
          {baseStrings.favourites}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            logout();
          }}
        >
          {baseStrings.logout}
        </MenuItem>
      </Menu>
    );
  };

  const ShowFRButton = () => {
    // wonky fix (:
    if (universityName === "University of Ottawa") {
      // TODO: Make sure University of Ottawa is name
      return <LanguageToggle className="button--blue button--nav" />;
    }
    return null;
  };

  const ShowUOttawaIcon = () => {
    // wonky fix (:
    if (universityName === "University of Ottawa") {
      // TODO: Make sure University of Ottawa is name
      return <img className="navbar__logo-union" src={unionLogo} alt="logo" />;
    }
    return null;
  };

  return (
    <div className="navbar">
      <button className="navbar__menu-icon" onClick={() => setDrawerOpen(true)}>
        <svg>
          <use href={sprites + "#icon-menu"}></use>
        </svg>
      </button>

      <Link className="navbar__logo-container" to="/home">
        <img className="navbar__logo-name" src={cpLogoName} alt="logo" />
        <ShowUOttawaIcon> </ShowUOttawaIcon>
      </Link>

      <div className="navbar__links">
        {tabs.map((tab, index) => (
          <NavLink
            className="navbar__link"
            activeClassName="navbar__link--active"
            key={index}
            to={tab.path}
          >
            {tab.text}
          </NavLink>
        ))}
      </div>

      <button
        className="navbar__emergency button--red button--nav"
        onClick={() => setEmergencyOpen(true)}
      >
        {baseStrings.emergencySupport}
      </button>

      <ShowFRButton> </ShowFRButton>

      <button
        className="navbar__profile button--blue button--nav button--profile"
        onClick={openMenu}
      >
        <img src={profile} alt="Menu" />
      </button>
      <CustomMenu />

      {profileOpen && (
        <CustomModal handleClose={() => setProfileOpen(false)}>
          <Profile />
        </CustomModal>
      )}
      {emergencyOpen && (
        <CustomModal handleClose={() => setEmergencyOpen(false)}>
          <Emergency />
        </CustomModal>
      )}

      <CustomDrawer
        placement={"left"}
        visible={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
      >
        <div className="navbar__drawer">
          <div className="navbar__drawer-name">{student.preferred_name}</div>
          <div className="navbar__drawer-email">{student.email}</div>

          {tabs.map((tab, index) => (
            <NavLink
              className="navbar__drawer-link"
              activeClassName="navbar__drawer-link--active"
              onClick={() => setDrawerOpen(false)}
              key={index}
              to={tab.path}
            >
              {tab.text}
            </NavLink>
          ))}
          <NavLink
            className="navbar__drawer-link"
            activeClassName="navbar__drawer-link--active"
            onClick={() => setDrawerOpen(false)}
            to={"/favourites"}
          >
            {baseStrings.myFavourites}
          </NavLink>

          <button
            className="navbar__drawer-link"
            onClick={() => {
              setProfileOpen(true);
              setDrawerOpen(false);
            }}
          >
            {baseStrings.profile}
          </button>

          <button className="navbar__drawer-logout" onClick={() => logout()}>
            {baseStrings.logout}
          </button>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default NavBar;
