import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

import axiosRequest from "util/axiosConfig";

import OnDemandFilters from "components/Filters/OnDemandFilters";
import OnDemandClass from "components/Items/OnDemandClass";
import Spinner from "components/Spinner/Spinner";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { routes } from "util/constants";

const OnDemand = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [resources, setResources] = useState({ data: [], loading: true });
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { fav_ondemand: favourites, preferred_language } = useSelector((state) => state.student);
  const universityId = useSelector(state => state.student.university.id);
  const { baseStrings } = useLanguage();

  useEffect(() => {
    setResources({ data: [], loading: true });

    let filteredUrl = `?language=${preferred_language}`;
    for (let filter of appliedFilters) {
      filteredUrl += `&tags=${filter}`;
    }

    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.onDemand(filteredUrl))
        .then((response) => {
          if (!isCancelled) {
            let filteredOnDemand = response.data.filter(onDemand => {
              return onDemand.university === universityId;
            });

            setResources({ data: filteredOnDemand, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [appliedFilters, preferred_language]);

  const handleFilter = (newId) => {
    let newAppliedFilters = [...appliedFilters];
    if (newAppliedFilters.includes(newId)) {
      setAppliedFilters(newAppliedFilters.filter((id) => id !== newId));
    } else {
      setAppliedFilters([...newAppliedFilters, newId]);
    }
  };

  return (
    <div className="list-page list-page--filters">
      <CustomDrawer visible={drawerOpen} handleClose={() => setDrawerOpen(false)}>
        <OnDemandFilters inDrawer appliedFilters={appliedFilters} handleFilter={handleFilter} />
      </CustomDrawer>

      <div className="list-page__filters">
        <OnDemandFilters appliedFilters={appliedFilters} handleFilter={handleFilter} />
      </div>

      <div className="list-page__content">
        <div className="list-page__title">{baseStrings.onDemandClasses}</div>
        <div className="list-page__heading">{baseStrings.filterByLang}</div>
        <button className="list-page__filter-button button--blue" onClick={() => setDrawerOpen(true)}>
          {baseStrings.filters}
        </button>

        <div className="list-page__list">
          {resources.loading ? (
            <div className="list-page__loading">
              <Spinner />
            </div>
          ) : (
            resources.data.map((resource, index) => {
              return (
                <OnDemandClass
                  content={resource}
                  key={index}
                  isFavourite={favourites.map((item) => item.id).includes(resource.id)}
                  besideFilters
                  hasImage
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
export default OnDemand;
