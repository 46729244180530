import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import { useSelector } from "react-redux";
import { useLanguage } from "language/hook";

import axiosRequest from "util/axiosConfig";

import MindfulnessExercise from "components/Items/MindfulnessExercise";
import Spinner from "components/Spinner/Spinner";
import LibraryFilters from "components/Filters/LibraryFilters";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import { routes } from "util/constants";

const Mindfulness = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [resources, setResources] = useState({ data: [], loading: true });
  const [appliedFilters, setAppliedFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { fav_mindfulness: favourites, preferred_language } = useSelector(
    (state) => state.student
  );

  const { baseStrings } = useLanguage();

  useEffect(() => {
    setResources({ data: [], loading: true });

    let filteredUrl = `?language=${preferred_language}`;
    for (let filterType in appliedFilters) {
      for (let filter of appliedFilters[filterType]) {
        filteredUrl += `&${filterType}=${filter}&`;
      }
    }

    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.mindfulness(filteredUrl))
        .then((response) => {
          if (!isCancelled) {
            setResources({ data: response.data, loading: false });
          }
        })
        .catch((error) => {
          console.log(error, error.response);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [appliedFilters, preferred_language]);

  const handleFilter = (newId, key) => {
    let newAppliedFilters = { ...appliedFilters };
    if (newAppliedFilters[key]) {
      if (newAppliedFilters[key].includes(newId)) {
        newAppliedFilters[key] = newAppliedFilters[key].filter(
          (id) => id !== newId
        );
      } else {
        newAppliedFilters[key] = [...newAppliedFilters[key], newId];
      }
    } else {
      newAppliedFilters[key] = [newId];
    }
    setAppliedFilters(newAppliedFilters);
  };

  return (
    <div className="list-page list-page--filters">
      <CustomDrawer
        visible={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
      >
        <LibraryFilters
          inDrawer
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
        />
      </CustomDrawer>

      <div className="list-page__filters">
        <LibraryFilters
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
        />
      </div>

      <div className="list-page__content">
        <div className="list-page__title">
          {baseStrings.mindfulnessResources}
        </div>
        <div className="list-page__heading">{`${baseStrings.uploadWeekly} ${baseStrings.filterByLang}`}</div>
        <div className="list-page__list">
          {resources.loading ? (
            <div className="list-page__loading">
              <Spinner />
            </div>
          ) : (
            resources.data.map((resource, index) => {
              return (
                <MindfulnessExercise
                  content={resource}
                  key={index}
                  isFavourite={favourites
                    .map((item) => item.id)
                    .includes(resource.id)}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Mindfulness;
