//axios
import axios from "axios";
import axiosRequest from "util/axiosConfig";
import moment from "moment";
import { routes } from "util/constants";

// redux
import store from "redux/_store";

import {
  SET_LINKS,
  SET_MESSAGE,
  SET_EVENTS_TAGS,
  SET_EVENTS_FACULTY,
  SET_ONDEMAND_TAGS,
  SET_RESOURCE_FILTERS,
  SET_CLUBS_FILTERS,
  SET_CLUBS_RESOURCES_FILTERS,
  SET_EVENTS_TODAY,
  SET_ALERT,
  CLEAR_ALERT_TIMER,
  HIDE_ALERT, SET_CLUBS_TYPES, SET_CLUBS_FACULTY,
} from "../types";

// Data that is pulled from backend before showing the page to users
export const setData = () => {
  store.dispatch((dispatch, getState) => {
    let data = getState().data;
    let { linksSet, messageSet, eventTagsSet, eventFacultySet, onDemandTagsSet, resourceFiltersSet, clubsFiltersSet, clubsTypesSet, clubsFacultySet, clubsResourcesFiltersSet,eventsTodaySet } = data;

    let message = JSON.parse(localStorage.getItem("message"));
    if (message) {
      let messageDate = message.dayStamp;
      if (moment(messageDate, "YYYY-MM-DD").isSame(moment(), "day") && !messageSet) {
        dispatch({
          type: SET_MESSAGE,
          message: message,
        });
      } else if (!messageSet) updateMessage(dispatch);
    } else if (!messageSet) updateMessage(dispatch);

    if (!linksSet) updateLinks(dispatch);
    if (!eventTagsSet) updateEventTags(dispatch);
    if (!eventFacultySet) updateEventFaculty(dispatch);
    if (!onDemandTagsSet) updateOnDemandTags(dispatch);
    if (!resourceFiltersSet) updateResourceFilters(dispatch);
    if (!clubsFiltersSet) updateClubsFilters(dispatch);
    if (!clubsTypesSet) updateClubsTypes(dispatch);
    if (!clubsFacultySet) updateClubsFaculty(dispatch);
    if (!clubsResourcesFiltersSet) updateClubsResourcesFilters(dispatch);
    if (!eventsTodaySet) updateEventsToday(dispatch);
  });
};

const updateLinks = (dispatch) => {
  axiosRequest
    .get(routes.links)
    .then((response) => {
      dispatch({
        type: SET_LINKS,
        links: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_LINKS,
        links: [],
      });
    });
};

const updateMessage = (dispatch) => {
  axiosRequest
    .get(routes.messageOfTheDay)
    .then((response) => {
      if (response.data[0]) {
        let messageOfTheDay = response.data[0];
        let object = { ...messageOfTheDay, dayStamp: moment().format("YYYY-MM-DD") };
        localStorage.setItem("message", JSON.stringify(object));
      }

      dispatch({
        type: SET_MESSAGE,
        message:
          response.data.length > 0
            ? response.data[0]
            : {
                message_en: "how are you currently feeling?",
                message_fr: "comment vous sentez-vous actuellement ?",
              },
      });
    })
    .catch(() => {
      dispatch({
        type: SET_MESSAGE,
        message: {
          message_en: "how are you currently feeling?",
          message_fr: "comment vous sentez-vous actuellement ?",
        },
      });
    });
};

const updateEventTags = (dispatch) => {
  axiosRequest
    .get(routes.eventTags)
    .then((response) => {
      dispatch({
        type: SET_EVENTS_TAGS,
        tags: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_EVENTS_TAGS,
        tags: [],
      });
    });
};

const updateEventFaculty = (dispatch) => {
  axiosRequest
    .get(routes.eventFaculty)
    .then((response) => {
      dispatch({
        type: SET_EVENTS_FACULTY,
        faculty: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_EVENTS_FACULTY,
        faculty: [],
      });
    });
};

const updateOnDemandTags = (dispatch) => {
  axiosRequest
    .get(routes.onDemandTags)
    .then((response) => {
      dispatch({
        type: SET_ONDEMAND_TAGS,
        tags: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_ONDEMAND_TAGS,
        tags: [],
      });
    });
};

const updateEventsToday = (dispatch) => {
  axiosRequest
    .get(routes.eventsToday)
    .then((response) => {
      dispatch({
        type: SET_EVENTS_TODAY,
        events: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_EVENTS_TODAY,
        events: [],
      });
    });
};

const updateResourceFilters = (dispatch) => {
  axios
    .all([axiosRequest.get(routes.resTags), axiosRequest.get(routes.resMethods), axiosRequest.get(routes.resGroups)])
    .then((response) => {
      let filters = {
        tags: response[0].data,
        method: response[1].data,
        group: response[2].data,
      };
      dispatch({
        type: SET_RESOURCE_FILTERS,
        filters,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_RESOURCE_FILTERS,
        filters: {
          tags: [],
          method: [],
          group: [],
        },
      });
    });
};

const updateClubsFilters = (dispatch) => {
  axios
    .all([axiosRequest.get(routes.amsType), axiosRequest.get(routes.amsFaculty)])
    .then((response) => {
      let filters = {
        types: response[0].data,
        faculty: response[1].data,
      };
      dispatch({
          type: SET_CLUBS_FILTERS,
          filters,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_CLUBS_FILTERS,
        filters: {
          types: [],
          faculty: [],
        },
      });
    });
};

const updateClubsTypes = (dispatch) => {
  axiosRequest
    .get(routes.amsType)
    .then((response) => {
      dispatch({
        type: SET_CLUBS_TYPES,
        types: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_CLUBS_TYPES,
        types: [],
      });
    });
};

const updateClubsFaculty = (dispatch) => {
  axiosRequest
    .get(routes.amsFaculty)
    .then((response) => {
      dispatch({
        type: SET_CLUBS_FACULTY,
        faculty: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_CLUBS_FACULTY,
        faculty: [],
      });
    });
};

const updateClubsResourcesFilters = (dispatch) => {
  axios
    .all([axiosRequest.get(routes.amsResourceType)])
    .then((response) => {
      let filters = {
        types: response[0].data,
      };
      dispatch({
          type: SET_CLUBS_RESOURCES_FILTERS,
          filters,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_CLUBS_RESOURCES_FILTERS,
        filters: {
          types: [],
        },
      });
    });
};

export const sendAlert = (text, type = "") => {
  store.dispatch((dispatch, getState) => {
    let timer = getState().data.alert.timer;
    if (timer) {
      dispatch({
        type: CLEAR_ALERT_TIMER,
      });
    }
    let newTimer = setTimeout(() => {
      dispatch({
        type: HIDE_ALERT,
      });
    }, 3000);

    dispatch({
      type: SET_ALERT,
      alert: {
        text: text,
        type: type,
        timer: newTimer,
        visible: true,
      },
    });
  });
};
