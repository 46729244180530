const getItemsTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        onCampusClass: "On-Campus Class",
        onDemandClass: "On-Demand Class",
        liveStreamClass: "Livestream Class",
        classPast: "Class has past",
        mindfulnessResource: "Mindfulness Resource",
        resource: "Resource",
        event: "Event",
        eventPast: "Event has past",
        //Miscellaneous
        readMore: "Read More",
        readLess: "Read Less",
        viewClass: "View Class",
        addToCalendar: "Add To Google Calendar",
        sendToFriend: "Send To Friend",
        addFavourite: "Add Favourite",
        removeFavourite: "Remove Favourite",
        confirmRemoveFavourite: "Are you sure you would like to remove favourite?",
        yes: "Yes",
        no: "No",
        learnMore: "Learn More",
        hostLink: "Host Link",
        clubs: "AMS-Clubs",
        amsResources: "AMS-Resources",
        nextOn: (dateTimeText) => `Next on ${dateTimeText}, see more dates on calendar`,
      }
    : {
        onCampusClass: "Cours sur campus",
        onDemandClass: "Cours sur demande",
        liveStreamClass: "Cours en direct",
        classPast: "Classe passé",
        mindfulnessResource: "Ressources en matière de pleine conscience",
        resource: "Ressource",
        event: "Événement",
        eventPast: "Événement passé",
        //Miscellaneous
        readMore: "En savoir plus",
        readLess: "En savoir moins",
        viewClass: "Voir la classe",
        addToCalendar: "Ajouter à mon calendrier Google",
        sendToFriend: "Envoyer à un(e) ami(e)",
        addFavourite: "Ajouter aux favoris",
        removeFavourite: "Supprimer des favoris",
        confirmRemoveFavourite: "Êtes-vous certain(e) de vouloir enlever de vos favoris",
        yes: "Oui",
        no: "Non",
        learnMore: "Savoir plus",
        hostLink: "Lien hôte",
        clubs: "AMS-Clubs",
        amsResources: "AMS-Resources",
        nextOn: (dateTimeText) => `Prochainement le ${dateTimeText}, voir plus de dates sur le calendrier`,
      };
};

export default getItemsTranslations;
