import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//Google Analytic
import ReactGA from "react-ga";

//Image
import cpLogo from "imgs/navbar/ww-logo.png";
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";
import LanguageToggle from "components/LanguageToggle/LanguageToggle";
import { useLanguage } from "language/hook";

//axios
import axiosRequest from "util/axiosConfig";
import { routes } from "util/constants";

const ResetPassword = () => {
  //Google Analystics
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { authStrings } = useLanguage();
  const [loading, setLoading] = useState(true); // True if request worked
  const [resString, setResString] = useState("");

  let { id, token } = useParams();

  let history = useHistory();
  const redirectToLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    //Prevent memory leak
    let isCancelled = false;
    async function verifyEmail() {
      axiosRequest
        .get(routes.activate(id, token))
        .then((res) => {
          if (!isCancelled) {
            if (res.data.response === "User successfully activated") setResString(authStrings.activatingSuccess);
            else setResString(authStrings.activatingInvalid);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setResString(authStrings.activatingError);
          setLoading(false);
        });
    }
    verifyEmail();
    return () => {
      isCancelled = true;
    };
  }, [id, token, authStrings.activatingError, authStrings.activatingInvalid, authStrings.activatingSuccess]);

  return (
    <div className="onboarding">
      <LanguageToggle className="onboarding__lang-toggle button--blue" />

      <div className="onboarding__logo-container">
        <img src={cpLogo} alt={"logo"} />
        <img src={CP} alt={"logo"} />
      </div>
      {loading ? (
        <div className="onboarding__content">
          <span className="onboarding__title">{authStrings.activating}</span>
          <Spinner size="4rem" />
        </div>
      ) : (
        <div className="onboarding__success">
          <span className="onboarding__title">{resString}</span>
          <button className="onboarding__link" onClick={redirectToLogin}>
            {authStrings.clickToLogin}
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
