import React from "react";
import { useSelector } from "react-redux";
import { useLanguage } from "language/hook";

import "./MessageBar.scss";

const MessageBar = () => {
  const { preferEnglish } = useLanguage();
  const { preferred_name } = useSelector((state) => state.student);
  const { messageOfTheDay } = useSelector((state) => state.data);

  return (
    <div className="message-bar">
      <span className="message-bar__text heading-primary">
        {preferEnglish
          ? `Hi ${preferred_name}, ${messageOfTheDay.message_en}`
          : `Salut ${preferred_name}, ${messageOfTheDay.message_fr}`}
      </span>
    </div>
  );
};

export default MessageBar;
