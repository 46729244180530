import React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Spinner = ({ size = "5rem", color = "#3870e0" }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size, color }} spin />;

  return <Spin indicator={antIcon} />;
};

export default Spinner;
