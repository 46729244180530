import React from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import NavBar from "components/NavBar/NavBar";
import Footer from "components/Footer/Footer";
import MessageBar from "components/MessageBar/MessageBar";
import LoadingPage from "pages/LoginSystem/LoadingPage";
import { setLanguage } from "redux/_actions/students";

const CustomRoute = ({ page, path, ...rest }) => {
  const { isLoading, isAuthenticated } = useSelector((state) => state.auth);
  const {
    linksSet,
    messageSet,
    eventTagsSet,
    eventFacultySet,
    onDemandTagsSet,
    resourceFiltersSet,
    clubsFiltersSet,
    clubsResourcesFiltersSet,
  } = useSelector((state) => state.data);

  let { search } = useLocation();
  let history = useHistory();

  // Set language if preference specifically set in URL
  if (search === "?lang=en" || search === "?lang=fr") {
    if (search === "?lang=en") setLanguage("en");
    else setLanguage("fr");
    history.push({
      pathname: path,
      search: "",
    });
  }

  // Set preference langauge if in local storage
  if (localStorage.getItem("lang")) {
    setLanguage(localStorage.getItem("lang"));
  }

  const loginPages = ["/login", "/register", "/forgot-password"];
  const home = ["/home"];
  //const mentalHealth ["/mental-health"];
  const navFlow = ["/mental-health/quiz"];
  const amsClubs = ["/ams-clubs"];

  let isHome =
    home.includes(path) ||
    path.startsWith("/validate") ||
    path.startsWith("/reset");
  let isLoginPage =
    loginPages.includes(path) ||
    path.startsWith("/validate") ||
    path.startsWith("/reset");
  let isNavFlow =
    navFlow.includes(path) ||
    path.startsWith("/validate") ||
    path.startsWith("/reset");
  let isAmsClub =
    amsClubs.includes(path) ||
    path.startsWith("/validate") ||
    path.startsWith("/reset");
  //let isMentalHealth = mentalHealth.includes(path) || path.startsWith("/validate") || path.startsWith("/reset");

  // TODO: Missing !eventsTodaySet
  let isDataLoading =
    !isLoginPage &&
    !linksSet &&
    !messageSet &&
    !eventTagsSet &&
    !onDemandTagsSet &&
    !resourceFiltersSet &&
    !clubsFiltersSet &&
    !clubsResourcesFiltersSet;

  return (
    <Route
      {...rest}
      path={path}
      render={() => {
        if (isLoading || isDataLoading) {
          return <LoadingPage isLoginPage={isLoginPage} />;
        } else if (isLoginPage && isAuthenticated) {
          return <Redirect to="/home" />;
        } else if (!isLoginPage && !isAuthenticated) {
          return <Redirect to="/login" />;
        } else if (!isLoginPage) {
          return (
            <div className="app">
              <NavBar />
              <MessageBar />
              {page}
              <Footer />
            </div>
          );
        } else {
          return page;
        }
      }}
    />
  );
};

export default CustomRoute;
