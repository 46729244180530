import React from "react";
import { useLanguage } from "language/hook";

import cpLogo from "imgs/navbar/master-logo.png";

import "./Footer.scss";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { bannerStrings } = useLanguage();
  const { pathname } = useLocation();

  let whitePages = ["/fitness", "/home", "/ams-clubs"];
  let isWhite = whitePages.includes(pathname);

  return (
    <div className={`footer ${isWhite ? "footer--white" : ""}`}>
      <img className="footer__image" src={cpLogo} alt="ww logo" />
      <div className="footer__details">
        <div className="footer__text text-footer">
          {bannerStrings.contactUsAt}{" "}
          <a className="footer__email" href="mailto: info@checkcompass.ca">
            info@checkcompass.ca
          </a>
        </div>
        <a
          className="footer__text text-footer"
          href="https://www.mywellnessworld.ca/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {bannerStrings.checkPrivacyPolicy}
        </a>
      </div>
    </div>
  );
};

export default Footer;
