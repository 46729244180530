import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

import axiosRequest from "util/axiosConfig";

import ClubsResourcesL from "../../../components/Items/ClubsResourceList";
import ClubsResourcesFilters from "../../../components/Filters/ClubsResourcesFilters";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import Spinner from "components/Spinner/Spinner";
import { routes } from "util/constants";

const ClubsResources = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [clubsResources, setClubsResources] = useState({ data: [], loading: true });
  const [appliedFilters, setAppliedFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const favourites = useSelector((state) => state.student.fav_clubsResources);
  const { baseStrings } = useLanguage();

  useEffect(() => {
    setClubsResources({ data: [], loading: true });

    let filteredUrl = "";
    for (let filterType in appliedFilters) {
      for (let filter of appliedFilters[filterType]) {
        filteredUrl += `${filterType}=${filter}&`;
      }
    }

    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.amsResources(filteredUrl))
        .then((response) => {
          if (!isCancelled) {
            setClubsResources({ data: response.data, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [appliedFilters]);

  const handleFilter = (newId, key) => {
    let newAppliedFilters = { ...appliedFilters };
    if (newAppliedFilters[key]) {
      if (newAppliedFilters[key].includes(newId)) {
        newAppliedFilters[key] = newAppliedFilters[key].filter((id) => id !== newId);
      } else {
        newAppliedFilters[key] = [...newAppliedFilters[key], newId];
      }
    } else {
      newAppliedFilters[key] = [newId];
    }
    setAppliedFilters(newAppliedFilters);
  };

  return (

    <div className="list-page list-page--filters">
      <CustomDrawer visible={drawerOpen} handleClose={() => setDrawerOpen(false)}>
        <ClubsResourcesFilters inDrawer appliedFilters={appliedFilters} handleFilter={handleFilter} />
      </CustomDrawer>

      <div className="list-page__filters">
        <ClubsResourcesFilters appliedFilters={appliedFilters} handleFilter={handleFilter} />
      </div>

      <div className="list-page__content">
        <div className="list-page__search-bar">
          <input className = "list-page__search-bar-box"
          type="text"
          onChange={(event) => {
          setSearchTerm(event.target.value);
          }}
          />
        </div>

        <div className="list-page__title">{baseStrings.browseAllClubsResources}</div>
        <button className="list-page__filter-button button--blue" onClick={() => setDrawerOpen(true)}>
          {baseStrings.filters}
        </button>

        <div className="list-page__list">
          {clubsResources.loading && (
            <div className="list-page__loading" id="loading">
              <Spinner />
            </div>
          )}

          {clubsResources.data.filter((resources)=> {
            if (searchTerm == "") {
              return resources
            } else if (resources.title.toLowerCase().includes(searchTerm.toLowerCase())) {
              return resources
            }
          }).map((resources) => (
            <ClubsResourcesL
              content={resources}
              key={resources.id}
              isFavourite={favourites.map((item) => item.id).includes(resources.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClubsResources;
