import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_ATTEMPT,
  LOGIN_FAIL,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types.js";

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  loginError: false,
  loginLoading: false,
  loginAttempts: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    case LOGIN_ATTEMPT:
      return {
        ...state,
        loginLoading: true,
        loginAttempts: state.loginAttempts + 1,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        loginAttempts: 0,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        loginLoading: false,
        loginError: true,
      };
    case LOGIN_RESET:
      return {
        ...state,
        loginError: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        loginLoading: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
