import React, { useState } from "react";

import { useLanguage } from "language/hook";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { favConstants } from "util/constants";
import {
  ItemDetails,
  ClubsDetails,
  ClubsSendToFriend,
  ClubsFavourite,
  ItemConfirm,
  ItemSocials,
} from "./ItemComponents";

// Functionality commented thoroughly in Event.js
const Clubs = ({
  isFavourite,
  content,
  isPopUp,
  besideFilters,
  fromFavourites,
}) => {
  const {
    title,
    description,
    icon,
    link,
    instagram,
    facebook,
    twitter,
    discord,
    additional_info,
  } = content;
  const { itemStrings } = useLanguage();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleFavourite = () => {
    if (!isFavourite) addFavourite(content, favConstants.club);
    else if (fromFavourites) setConfirmOpen(true);
    else removeFavourite(content, favConstants.club);
  };

  const handleConfirmRemove = () => {
    removeFavourite(content, favConstants.club);
    setConfirmOpen(false);
  };

  return (
    <div
      className={`item ${isPopUp ? "item--popup" : "item--list"} ${
        besideFilters ? "item--filters" : ""
      }`}
    >
      <ItemDetails
        title={title}
        description={description}
        icon={icon}
        link={link}
        additional_info={[additional_info]}
      />
      <div className="item__options">
        <ClubsSendToFriend content={content} title={itemStrings.clubs} />
        <ClubsFavourite
          isFavourite={isFavourite}
          handleFavourite={handleFavourite}
        />
      </div>
      <ItemSocials
        discord={discord}
        facebook={facebook}
        instagram={instagram}
        twitter={twitter}
      ></ItemSocials>

      {confirmOpen && (
        <ItemConfirm
          setConfirmOpen={setConfirmOpen}
          handleConfirm={handleConfirmRemove}
        />
      )}
    </div>
  );
};

export default Clubs;
