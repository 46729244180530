import React, { useState } from "react";
import { useLanguage } from "language/hook";

import {
  getDateTimeText,
  getFirstDate,
  getCalendarLink,
} from "util/dataHelpers";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { useSelector } from "react-redux";
import { favConstants } from "util/constants";
import {
  ItemDetails,
  ItemLink,
  ItemCalendar,
  ItemSendToFriend,
  ItemFavourite,
  ItemConfirm,
} from "./ItemComponents";

const Event = ({ event, isPopUp, fromFavourites }) => {
  const {
    address,
    title_en,
    title_fr,
    description_en,
    description_fr,
    event_link,
    host_link,
    start_time,
    end_time,
    date,
    dates,
    id,
  } = event;

  const [confirmOpen, setConfirmOpen] = useState(false);
  // Get ids of Events that are favourited by current student
  const favourites = useSelector((state) => state.student.fav_event);
  let isFavourite = favourites.map((item) => item.id).includes(id);

  // Retrieve preferred language and required translations
  const { preferEnglish, itemStrings } = useLanguage();

  // Date is null if event is shown on favourites but non-null when opened from calendar or todays events
  // Must find the next occurence of the event to display to users
  let eventDate = date ? date : getFirstDate(dates);
  // Convert date to formatted string ie. 2020/06/12 -> Thursday, June 12
  let dateTimeText = eventDate
    ? getDateTimeText(eventDate, start_time, end_time)
    : itemStrings.eventPast;
  // If event has specific date show that date string only, if event is over display "Event has past" else if event has upcoming date then "Next on (date)"
  let dateString =
    date || dateTimeText === itemStrings.eventPast
      ? dateTimeText
      : itemStrings.nextOn(dateTimeText);

  // Choose which translations to use for content from database
  let title = preferEnglish ? title_en : title_fr;
  let description = preferEnglish ? description_en : description_fr;

  let calendarLink = eventDate
    ? getCalendarLink(
        title,
        description,
        event_link,
        eventDate,
        start_time,
        end_time,
        host_link
      )
    : getCalendarLink(title, description, event_link);

  const handleFavourite = () => {
    // Since date is specific to how the date is displayed but not stored is it removed (events only)
    let { date, ...favEvent } = event;
    // Adding to favourites happens right away
    if (!isFavourite) addFavourite(favEvent, favConstants.event);
    // If trying to remove favourite from favourites page bring up confirm pop up
    // This is important as without the confirm pop up if they hit remove on favourites page
    // the item would disappear right away and be hard for the user to find again
    else if (fromFavourites) setConfirmOpen(true);
    // If trying to remove favourite on other pages allow it to happen right away
    else removeFavourite(favEvent, favConstants.event);
  };

  // Function that handles the removal of favourite when user confirms it on the pop up
  const handleConfirmRemove = () => {
    let { date, ...favEvent } = event;
    removeFavourite(favEvent, favConstants.event);
    // Close the pop up
    setConfirmOpen(false);
  };

  return (
    <div
      className={`item ${isPopUp ? "item--popup" : "item--list"}`}
      style={{ paddingTop: "5rem" }}
    >
      {/* if isPopUp sizing must be adjusted to fit the modal */}
      <ItemDetails
        title={title}
        description={description}
        optionalNotes={[address, dateString]}
      />
      <div className="item__options">
        {event_link && (
          <ItemLink link={event_link} label={itemStrings.learnMore} />
        )}
        {host_link && (
          <ItemLink link={host_link} label={itemStrings.hostLink} />
        )}
        <ItemCalendar calendarLink={calendarLink} />
        <ItemSendToFriend
          content={event}
          title={itemStrings.event}
          dateTimeText={dateTimeText}
        />
        <ItemFavourite
          isFavourite={isFavourite}
          handleFavourite={handleFavourite}
        />
      </div>
      {confirmOpen && (
        <ItemConfirm
          setConfirmOpen={setConfirmOpen}
          handleConfirm={handleConfirmRemove}
        />
      )}
    </div>
  );
};

export default Event;
