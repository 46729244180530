//axios
import axiosRequest from "util/axiosConfig";
// redux
import store from "redux/_store";
import { routes } from "util/constants";
import Cookies from "js-cookie";

import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_ATTEMPT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SET_STUDENT,
  RESET_STUDENT,
} from "../types";

export const loadUser = () => {
  store.dispatch((dispatch) => {
    dispatch({ type: USER_LOADING });

    // Verify the token and load student
    axiosRequest
      .get(routes.studentGet)
      .then((res) => {
        if (res.data.length > 0) {
          // Set Student Data in Redux
          let student = res.data[0];

          dispatch({
            type: SET_STUDENT,
            student,
          });
          dispatch({
            type: USER_LOADED,
          });
          console.log("STUENDT: ", student);
          const host = window.location.host.split(".");
          // if (host[0] !== student.email.split("@")[1].split(".")[0]) {
          //   if (window.location.host.split(".").length === 2) {
          //     const replacePath =
          //       window.location.protocol +
          //       "//" +
          //       student.email.split("@")[1].split(".")[0] +
          //       "." +
          //       window.location.host;
          //     window.location.replace(replacePath);
          //   } else if (
          //     (host.length === 3 && host[0] === "dev") ||
          //     (host.length === 4 && host[1] === "dev")
          //   ) {
          //     const hostLength = host.length;
          //     const replacePath =
          //       window.location.protocol +
          //       "//" +
          //       student.email.split("@")[1].split(".")[0] +
          //       "." +
          //       host[hostLength - 3] +
          //       "." +
          //       host[hostLength - 2] +
          //       "." +
          //       host[hostLength - 1];
          //     window.location.replace(replacePath);
          //   } else if (
          //     student.email.split("@")[1].split(".")[0] !==
          //     window.location.host.split(".")[0]
          //   ) {
          //     const replacePath =
          //       window.location.protocol +
          //       "//" +
          //       student.email.split("@")[1].split(".")[0] +
          //       "." +
          //       window.location.host.split(".")[1] +
          //       "." +
          //       window.location.host.split(".")[2];
          //     window.location.replace(replacePath);
          //   }
          // }
        } else {
          Cookies.remove("Authorization");
          dispatch({
            type: AUTH_ERROR,
          });
        }
      })
      .catch(() => {
        Cookies.remove("Authorization");
        dispatch({
          type: AUTH_ERROR,
        });
      });
  });
};

export const login = (email, password) => {
  store.dispatch((dispatch) => {
    dispatch({
      type: LOGIN_ATTEMPT,
    });

    //Request Body
    const body = {
      email: email, // Student usernames are emails
      password: password,
    };

    // Attempt Login
    axiosRequest
      .post(routes.login, body)
      .then((res) => {
        let host = window.location.host.split(".");
        let subDomains = "";
        // if (
        //   (host.length === 3 && host[0] === "dev") ||
        //   (host.length === 4 && host[1] === "dev")
        // ) {
        //   host = "dev." + host[host.length - 2] + "." + host[host.length - 1];
        // } else
        if (host.length > 2) {
          host = host[host.length - 2] + "." + host[host.length - 1];
          subDomains = "." + host;
        }

        if (!subDomains) {
          subDomains = "." + host.join(".");
        }
        // Cookies.set("domain", subDomains);
        // Cookies.set("Authorization", `Token ${res.data.key}`);
        document.cookie =
          "Authorization" +
          "=" +
          `Token ${res.data.key}` +
          "; domain=" +
          subDomains +
          ";path=/";
        // Access Student Data
        axiosRequest
          .get(routes.studentGet)
          .then((res) => {
            // Set Student Data in Redux
            let student = res.data[0];
            console.log("studentGet: ", student);
            dispatch({
              type: SET_STUDENT,
              student,
            });
            dispatch({
              type: LOGIN_SUCCESS,
            });
            const host = window.location.host.split(".");
            let replacePath = "";
            if (host.length === 2) {
              replacePath =
                window.location.protocol +
                "//" +
                email.split("@")[1].split(".")[0] +
                "." +
                host.join(".");
            } else if (
              (host.length === 3 && host[0] === "dev") ||
              (host.length === 4 && host[1] === "dev")
            ) {
              const hostLength = host.length;
              replacePath =
                window.location.protocol +
                "//" +
                email.split("@")[1].split(".")[0] +
                "." +
                host[hostLength - 3] +
                "." +
                host[hostLength - 2] +
                "." +
                host[hostLength - 1];
            } else if (host[0] !== email.split("@")[1].split(".")[0]) {
              replacePath =
                window.location.protocol +
                "//" +
                email.split("@")[1].split(".")[0] +
                "." +
                host[1] +
                "." +
                host[2];
            }
            // if (replacePath) {
            //   window.location.replace(replacePath);
            // }
          })
          .catch((err) => {
            dispatch({
              type: LOGIN_FAIL,
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
        });
      });
  });
};

export const logout = () => {
  store.dispatch((dispatch) => {
    // Set Auth Token to Expire in the past so it is removed from browser immediately
    // Cookies.remove("Authorization");
    let host = window.location.host.split(".");
    if (host.length > 2) {
      host = host[host.length - 2] + "." + host[host.length - 1];
    }
    let subDomains = "." + host;
    document.cookie =
      "Authorization" + "=" + `` + "; domain=" + subDomains + ";path=/";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    dispatch({
      type: RESET_STUDENT,
    });
  });
};
