import React, { useState } from "react";

import { getCalendarLink } from "util/dataHelpers";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { favConstants } from "util/constants";
import { useLanguage } from "language/hook";
import { ItemDetails, ItemLink, ItemCalendar, ItemSendToFriend, ItemFavourite, ItemConfirm } from "./ItemComponents";

// Functionality commented thoroughly in Event.js
const MindfulnessExercise = ({ isFavourite, content, isPopUp, fromFavourites }) => {
  const { title, description, link, author_or_note } = content;
  const { itemStrings } = useLanguage();
  const [confirmOpen, setConfirmOpen] = useState(false);

  let calendarLink = getCalendarLink(title, description, link);

  const handleFavourite = () => {
    if (!isFavourite) addFavourite(content, favConstants.mindfulness);
    else if (fromFavourites) setConfirmOpen(true);
    else removeFavourite(content, favConstants.mindfulness);
  };

  const handleConfirmRemove = () => {
    removeFavourite(content, favConstants.mindfulness);
    setConfirmOpen(false);
  };

  return (
    <div className={`item ${isPopUp ? "item--popup" : "item--list"}`}>
      <ItemDetails title={title} description={description} optionalNotes={[author_or_note]} />
      <div className="item__options">
        <ItemLink link={link} label={itemStrings.learnMore} />
        <ItemCalendar calendarLink={calendarLink} />
        <ItemSendToFriend content={content} title={itemStrings.mindfulnessResource} />
        <ItemFavourite isFavourite={isFavourite} handleFavourite={handleFavourite} />
      </div>

      {confirmOpen && <ItemConfirm setConfirmOpen={setConfirmOpen} handleConfirm={handleConfirmRemove} />}
    </div>
  );
};

export default MindfulnessExercise;
