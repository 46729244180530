import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";

import { routes } from "util/constants";
import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";
import { getDatesForWeek, containsDate, getTimeRange } from "util/dataHelpers";
import CustomModal from "components/CustomModal/CustomModal";

import moment from "moment";
import axiosRequest from "util/axiosConfig";

import LiveStreamClass from "components/Items/LiveStreamClass";
import Spinner from "components/Spinner/Spinner";

const LiveStream = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [currMonday, setCurrMonday] = useState(moment().startOf("isoWeek"));
  const [weekDays, setWeekDays] = useState(getDatesForWeek(moment().startOf("isoWeek")));
  const [selectedClass, setSelectedClass] = useState(null);
  const [resources, setResources] = useState({
    data: [],
    loading: false,
  });

  const favourites = useSelector((state) => state.student.fav_livestream);
  const universityId = useSelector(state => state.student.university.id);

  const { preferEnglish, baseStrings, calStrings } = useLanguage();

  useEffect(() => {
    let isCancelled = false;

    async function fetchData() {
      await axiosRequest
        .get(routes.livestreams(currMonday))
        .then((response) => {
          if (!isCancelled) {
            let filteredLiveStreams = response.data.filter(livestream => {
              return livestream.university === universityId;
            });

          setResources({ data: filteredLiveStreams, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [currMonday]);

  const nextWeek = () => {
    let newMonday = currMonday.clone().add(7, "days");
    setCurrMonday(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  const prevWeek = () => {
    let newMonday = currMonday.clone().subtract(7, "days");
    setCurrMonday(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  const currWeek = () => {
    let newMonday = moment().startOf("isoWeek");
    setCurrMonday(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  let isCurrentWeek = moment().isBetween(currMonday, currMonday.clone().add(7, "days"));
  let weeksAhead = currMonday.diff(moment().startOf("isoWeek"), "weeks");
  let futureLimit = 3;

  let sortedClasses = [...resources.data];
  sortedClasses = sortedClasses.sort((a, b) => moment(a.start_time, "HH:mm:ss") - moment(b.start_time, "HH:mm:ss"));

  return (
    <>
      <div className="class-page">
        <div className="class-page__title">{baseStrings.liveStreamTitle}</div>
        <div className="class-page__buttons">
          {weeksAhead > 0 && (
            <button className="class-page__button--prev button--blue" onClick={prevWeek}>
              {calStrings.prev}
            </button>
          )}
          {!isCurrentWeek && (
            <button className="class-page__button--today button--blue" onClick={currWeek}>
              {calStrings.today}
            </button>
          )}
          {weeksAhead < futureLimit && (
            <button className="class-page__button--next button--blue" onClick={nextWeek}>
              {calStrings.next}
            </button>
          )}
        </div>
        {resources.loading ? (
          <div className="class-page__loading">
            <Spinner />
          </div>
        ) : (
          <>
            {weekDays.map((day, index) => {
              return (
                <div className={`class-page__day ${day.hasPast ? "class-page__day--past" : ""}`} key={index}>
                  <span className="class-page__date">{day.date}</span>
                  <div className="class-page__classes">
                    {sortedClasses.map((resource) => {
                      return containsDate(resource.dates, day.dateString) ? (
                        <div
                          className={`class-item ${day.hasPast ? "class-item--past" : ""}`}
                          key={`${resource.id}${day.dateString}`}
                          onClick={() => setSelectedClass({ ...resource, date: day.dateString })}
                        >
                          <div className="class-item__title">
                            {preferEnglish ? resource.title_en : resource.title_fr}
                          </div>
                          <div className="class-item__info">{getTimeRange(resource.start_time, resource.end_time)}</div>
                          {resource.icon && (
                            <img className="class-item__icon" alt="fitness-icon" src={resource.icon.image} />
                          )}
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      {selectedClass && (
        <CustomModal handleClose={() => setSelectedClass(null)}>
          <LiveStreamClass
            content={selectedClass}
            isFavourite={favourites.map((item) => item.id).includes(selectedClass.id)}
            key={selectedClass.id}
            isPopUp
          />
        </CustomModal>
      )}
    </>
  );
};

export default LiveStream;
