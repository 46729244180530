import { useSelector } from "react-redux";

import getAuthTranslations from "./auth";
import getBaseTranslations from "./base";
import getBannerTranslations from "./banners";
import getCalendarTranslations from "./calendar";
import getItemsTranslations from "./items";
import getUtilTranslations from "./util";

export const useLanguage = () => {
  const { preferred_language } = useSelector((state) => state.student);
  const preferEnglish = preferred_language === "en";

  let authStrings = getAuthTranslations(preferEnglish);
  let bannerStrings = getBannerTranslations(preferEnglish);
  let baseStrings = getBaseTranslations(preferEnglish);
  let calStrings = getCalendarTranslations(preferEnglish);
  let itemStrings = getItemsTranslations(preferEnglish);
  let utilStrings = getUtilTranslations(preferEnglish);

  return { preferEnglish, authStrings, baseStrings, bannerStrings, calStrings, itemStrings, utilStrings };
};
