import axios from "axios";
import dotenv from "dotenv";
import Cookies from "js-cookie";
// process.env.REACT_APP_API_BASE_URL
dotenv.config();
console.log(process.env);
const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

axiosRequest.interceptors.request.use((config) => {
  config.headers.Authorization = Cookies.get("Authorization");
  config.headers["Content-Type"] = "application/json";
  return config;
});

export default axiosRequest;
