import React, { useState } from "react";

import { useLanguage } from "language/hook";
import { getCalendarLink, getDateTimeText, getFirstDate } from "util/dataHelpers";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { favConstants } from "util/constants";
import { ItemDetails, ItemLink, ItemCalendar, ItemSendToFriend, ItemFavourite, ItemConfirm } from "./ItemComponents";

// Functionality commented thoroughly in Event.js
const OnCampusClass = ({ isFavourite, isPopUp, content, fromFavourites }) => {
  const {
    title_en,
    title_fr,
    description_en,
    description_fr,
    link,
    location,
    start_time,
    end_time,
    dates,
    date,
  } = content;
  const { preferEnglish, itemStrings } = useLanguage();
  const [confirmOpen, setConfirmOpen] = useState(false);

  let eventDate = date ? date : getFirstDate(dates);
  let dateTimeText = eventDate ? getDateTimeText(eventDate, start_time, end_time) : itemStrings.classPast;
  let dateString = date || dateTimeText === itemStrings.classPast ? dateTimeText : itemStrings.nextOn(dateTimeText);

  let title = preferEnglish ? title_en : title_fr;
  let description = preferEnglish ? description_en : description_fr;

  let calendarLink = eventDate
    ? getCalendarLink(title, description, link, eventDate, start_time, end_time)
    : getCalendarLink(title, description, link);

  const handleFavourite = () => {
    if (!isFavourite) addFavourite(content, favConstants.onCampus);
    else if (fromFavourites) setConfirmOpen(true);
    else removeFavourite(content, favConstants.onCampus);
  };

  const handleConfirmRemove = () => {
    removeFavourite(content, favConstants.onCampus);
    setConfirmOpen(false);
  };

  return (
    <div className={`item ${isPopUp ? "item--popup" : "item--list"}`}>
      <ItemDetails title={title} description={description} optionalNotes={[location, dateString]} />

      <div className="item__options">
        <ItemLink label={itemStrings.viewClass} link={link} />
        <ItemCalendar calendarLink={calendarLink} />
        <ItemSendToFriend content={content} title={itemStrings.onCampusClass} dateTimeText={dateTimeText} />
        <ItemFavourite handleFavourite={handleFavourite} isFavourite={isFavourite} />
      </div>

      {confirmOpen && <ItemConfirm setConfirmOpen={setConfirmOpen} handleConfirm={handleConfirmRemove} />}
    </div>
  );
};

export default OnCampusClass;
