import React from "react";
import sprites from "imgs/sprites.svg";
import { handleCopy } from "util/copyToClipboard";
import { useLanguage } from "language/hook";
import { useToggle } from "hooks";
import CustomModal from "components/CustomModal/CustomModal";
import CP from "imgs/home/assessment.png";
import IG from "imgs/ams-clubs/socials/instagram-logo.png";

export const ClubsDetails = ({
  title,
  description,
  icon,
  additional_info = [],
}) => {
  const { clubsStrings } = useLanguage();
  const [fullDesc, toggleFullDesc] = useToggle(false);
  const descLimit = 160;

  description =
    description.length > descLimit
      ? fullDesc
        ? description
        : `${description.substring(0, descLimit)}...`
      : description;

  return (
    <div className="item__details">
      <div className="item__container">
        <div className="item__title_AMS">{title}</div>
        <div className="item__description">
          {description}
          {description.length > descLimit && (
            <button className="item__read-more" onClick={toggleFullDesc}>
              {fullDesc ? clubsStrings.readLess : clubsStrings.readMore}
            </button>
          )}
        </div>
      </div>
      {additional_info.map((note, index) => {
        return (
          note && (
            <div className="item__note" key={index}>
              {note}
            </div>
          )
        );
      })}
    </div>
  );
};

export const ClubsSendToFriend = ({ content, title }) => {
  return (
    <div className="item__option" onClick={() => handleCopy(content, title)}>
      <svg className="item__icon">
        <use href={sprites + "#icon-copy"}></use>
      </svg>
    </div>
  );
};

export const ClubsFavourite = ({ isFavourite, handleFavourite }) => {
  return (
    <div className="item__option" onClick={handleFavourite}>
      <svg
        className={`item__icon item__heart ${
          isFavourite ? "item__heart--filled" : ""
        }`}
      >
        <use href={sprites + "#icon-heart"}></use>
      </svg>
    </div>
  );
};

export const ClubsConfirm = ({ setConfirmOpen, handleConfirm }) => {
  const { itemStrings } = useLanguage();

  return (
    <CustomModal handleClose={() => setConfirmOpen(false)}>
      <div className="item-confirmation">
        <div className="item-confirmation__message heading-tertiary">
          {itemStrings.confirmRemoveFavourite}
        </div>
        <button className="button--red" onClick={handleConfirm}>
          {itemStrings.yes}
        </button>
        <button className="button--white" onClick={() => setConfirmOpen(false)}>
          {itemStrings.no}
        </button>
      </div>
    </CustomModal>
  );
};

export const ItemDetails = ({
  title,
  description,
  link,
  optionalNotes = [],
}) => {
  const { itemStrings } = useLanguage();
  const [fullDesc, toggleFullDesc] = useToggle(false);
  const descLimit = 160;

  description =
    description.length > descLimit
      ? fullDesc
        ? description
        : `${description.substring(0, descLimit)}...`
      : description;

  return (
    <div className="item__details">
      <a
        className="item__option"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="item__title">{title}</div>
        <svg className="item__icon">
          <use href={sprites + "#icon-paper"}></use>
        </svg>
      </a>
      <div className="item__description">
        {description}
        {description.length > descLimit && (
          <button className="item__read-more" onClick={toggleFullDesc}>
            {fullDesc ? itemStrings.readLess : itemStrings.readMore}
          </button>
        )}
      </div>
      {optionalNotes.map((note, index) => {
        return (
          note && (
            <div className="item__note" key={index}>
              {note}
            </div>
          )
        );
      })}
    </div>
  );
};

export const ItemLink = ({ link, label }) => (
  <a
    className="item__option"
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg className="item__icon">
      <use href={sprites + "#icon-web"}></use>
    </svg>
    <span>{label}</span>
  </a>
);

export const ItemCalendar = ({ calendarLink }) => {
  const { itemStrings } = useLanguage();

  return (
    <a
      className="item__option"
      href={calendarLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg className="item__icon">
        <use href={sprites + "#icon-calendar-blue"}></use>
      </svg>
      <span>{itemStrings.addToCalendar}</span>
    </a>
  );
};

export const ItemSendToFriend = ({ content, title, dateTimeText }) => {
  const { itemStrings } = useLanguage();

  return (
    <div
      className="item__option"
      onClick={() => handleCopy(content, title, dateTimeText)}
    >
      <svg className="item__icon">
        <use href={sprites + "#icon-copy"}></use>
      </svg>
      <span>{itemStrings.sendToFriend}</span>
    </div>
  );
};

export const ItemFavourite = ({ isFavourite, handleFavourite }) => {
  const { itemStrings } = useLanguage();
  let label = isFavourite
    ? itemStrings.removeFavourite
    : itemStrings.addFavourite;

  return (
    <div className="item__option" onClick={handleFavourite}>
      <svg
        className={`item__icon item__heart ${
          isFavourite ? "item__heart--filled" : ""
        }`}
      >
        <use href={sprites + "#icon-heart"}></use>
      </svg>
      <span>{label}</span>
    </div>
  );
};

export const ItemSocials = ({ instagram, facebook, twitter, discord }) => {
  const { itemStrings } = useLanguage();

  return (
    <div className="socials social-inline-flex">
      {instagram ? (
        <a
          className="item__option"
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#bc2a8d"
              class="bi bi-instagram"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
          </h4>
        </a>
      ) : (
        ""
      )}
      {facebook ? (
        <a
          className="item__option"
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#4267B2"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
            </svg>
          </h4>
        </a>
      ) : (
        ""
      )}

      {twitter ? (
        <a
          className="item__option"
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#1DA1F2"
              class="bi bi-twitter"
              viewBox="0 0 16 16"
            >
              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
            </svg>
          </h4>
        </a>
      ) : (
        ""
      )}
      {discord ? (
        <a
          className="item__option"
          href={discord}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#7289da"
              class="bi bi-discord"
              viewBox="0 0 16 16"
            >
              <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z" />
              <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z" />
            </svg>
          </h4>
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export const ItemConfirm = ({ setConfirmOpen, handleConfirm }) => {
  const { itemStrings } = useLanguage();

  return (
    <CustomModal handleClose={() => setConfirmOpen(false)}>
      <div className="item-confirmation">
        <div className="item-confirmation__message heading-tertiary">
          {itemStrings.confirmRemoveFavourite}
        </div>
        <button className="button--red" onClick={handleConfirm}>
          {itemStrings.yes}
        </button>
        <button className="button--white" onClick={() => setConfirmOpen(false)}>
          {itemStrings.no}
        </button>
      </div>
    </CustomModal>
  );
};
