import React, { useRef, useState } from "react";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";
import Filter from "./Filter";
import { GoPlus } from "react-icons/go";

const FITNESS_ID = "fitness";

const EventFilters = ({
  appliedFilters,
  handleFilter,
  inDrawer,
  besideCalendar,
}) => {
  const { preferEnglish, baseStrings, calStrings } = useLanguage();
  const allEventFilters = useSelector((state) => state.data.eventTags);
  const universityId = useSelector((state) => state.student.university.id);
  const eventFilters = allEventFilters.filter(
    (event) => event.university === universityId
  );
  const eventFaculty = useSelector((state) => state.data.eventFaculty);

  const [setLanguageActive, setLanguageActiveState] = useState("");
  const [setLanguageHeight, setLanguageHeightState] = useState("0px");
  const [setLanguageRotate, setLanguageRotateState] = useState("");

  const [setLocationActive, setLocationActiveState] = useState("");
  const [setLocationHeight, setLocationHeightState] = useState("0px");
  const [setLocationRotate, setLocationRotateState] = useState("");

  const [setTagsActive, setTagsActiveState] = useState("");
  const [setTagsHeight, setTagsHeightState] = useState("0px");
  const [setTagsRotate, setTagsRotateState] = useState("");

  const [setFacultyActive, setFacultyActiveState] = useState("");
  const [setFacultyHeight, setFacultyHeightState] = useState("0px");
  const [setFacultyRotate, setFacultyRotateState] = useState("");

  const [setUniversitiesActive, setUniversitiesActiveState] = useState("");
  const [setUniversitiesHeight, setUniversitiesHeightState] = useState("0px");
  const [setUniversitiesRotate, setUniversitiesRotateState] = useState("");

  const languageContent = useRef(null);
  const locationContent = useRef(null);
  const tagsContent = useRef(null);
  const facultyContent = useRef(null);
  const universitiesContent = useRef(null);

  const toggleLanguage = () => {
    setLanguageActiveState(setLanguageActive === "" ? "active" : "");
    setLanguageHeightState(
      setLanguageActive === "active"
        ? "0px"
        : `${languageContent.current.scrollHeight}px`
    );
    setLanguageRotateState(setLanguageActive === "active" ? "" : "rotate");
  };

  const toggleLocation = () => {
    setLocationActiveState(setLocationActive === "" ? "active" : "");
    setLocationHeightState(
      setLocationActive === "active"
        ? "0px"
        : `${locationContent.current.scrollHeight}px`
    );
    setLocationRotateState(setLocationActive === "active" ? "" : "rotate");
  };

  const toggleTags = () => {
    setTagsActiveState(setTagsActive === "" ? "active" : "");
    setTagsHeightState(
      setTagsActive === "active"
        ? "0px"
        : `${tagsContent.current.scrollHeight}px`
    );
    setTagsRotateState(setTagsActive === "active" ? "" : "rotate");
  };

  const toggleFaculty = () => {
    setFacultyActiveState(setFacultyActive === "" ? "active" : "");
    setFacultyHeightState(
      setFacultyActive === "active"
        ? "0px"
        : `${facultyContent.current.scrollHeight}px`
    );
    setFacultyRotateState(setFacultyActive === "active" ? "" : "rotate");
  };

  const toggleUniversities = () => {
    setUniversitiesActiveState(setUniversitiesActive === "" ? "active" : "");
    setUniversitiesHeightState(
      setUniversitiesActive === "active"
        ? "0px"
        : `${universitiesContent.current.scrollHeight}px`
    );
    setUniversitiesRotateState(
      setUniversitiesActive === "active" ? "" : "rotate"
    );
  };
  console.log("eventFilter: ", eventFilters);

  return (
    <div
      className={`filters ${inDrawer ? "filters--drawer" : ""} ${
        besideCalendar ? "filters--calendar" : ""
      }`}
    >
      <h1 className="filters__text">Filters</h1>
      <div className="filters__title">
        <button
          className={`filters__button ${setLanguageActive}`}
          onClick={toggleLanguage}
        >
          {baseStrings.language}{" "}
          <GoPlus className={`icon ${setLanguageRotate}`} size="13px" />
        </button>
      </div>
      <div
        ref={languageContent}
        style={{ maxHeight: `${setLanguageHeight}` }}
        className="filters__group"
      >
        {baseStrings.languageFilters.map((filter, index) => {
          const selected = preferEnglish ? "en" : "fr";
          return (
            <Filter
              key={index}
              type="language"
              filter={filter}
              appliedFilters={appliedFilters}
              handleFilter={handleFilter}
              selected={selected === filter.value}
            />
          );
        })}
      </div>

      <div className="filters__title">
        <button
          className={`filters__button ${setLocationActive}`}
          onClick={toggleLocation}
        >
          {baseStrings.location}{" "}
          <GoPlus size="13px" className={`icon ${setLocationRotate}`} />
        </button>
      </div>
      <div
        ref={locationContent}
        style={{ maxHeight: `${setLocationHeight}` }}
        className="filters__group"
      >
        {baseStrings.locationFilters.map((filter, index) => {
          return (
            <Filter
              key={index}
              type="location"
              filter={filter}
              appliedFilters={appliedFilters}
              handleFilter={handleFilter}
            />
          );
        })}
      </div>

      <div className="filters__title">
        <button
          className={`filters__button ${setTagsActive}`}
          onClick={toggleTags}
        >
          {baseStrings.tags}{" "}
          <GoPlus className={`icon ${setTagsRotate}`} size="13px" />
        </button>
      </div>
      <div
        ref={tagsContent}
        style={{ maxHeight: `${setTagsHeight}` }}
        className="filters__group"
      >
        {eventFilters.map((filter, index) => {
          return (
            <div
              className="filter"
              key={index}
              onClick={() => handleFilter(filter.id, "tags")}
            >
              <svg
                className={`filter__svg ${
                  appliedFilters["tags"] &&
                  appliedFilters["tags"].includes(filter.id)
                    ? "filter__svg--filled"
                    : ""
                }`}
              >
                <circle
                  className="filter__svg-border"
                  cx="11"
                  cy="11"
                  r="9.6"
                  fill="none"
                />
                <circle
                  className="filter__svg-fill"
                  cx="11"
                  cy="11"
                  r="9.3"
                  stroke="white"
                  fill="white"
                />
              </svg>
              <div className="filter__label">
                {preferEnglish ? filter.label_en : filter.label_fr}
              </div>
            </div>
          );
        })}
        {/* Hardcoded Fitness tag for livestreams and onCampus classes */}
        {/* <div
          className="filter"
          key={eventFilters.length}
          onClick={() => handleFilter(FITNESS_ID, "tags")}
        >
          <svg
            className={`filter__svg ${
              appliedFilters["tags"] &&
              appliedFilters["tags"].includes(FITNESS_ID)
                ? "filter__svg--filled"
                : ""
            }`}
          >
            <circle
              className="filter__svg-border"
              cx="11"
              cy="11"
              r="9.6"
              fill="none"
            />
            <circle
              className="filter__svg-fill"
              cx="11"
              cy="11"
              r="9.3"
              stroke="white"
              fill="white"
            />
          </svg>
          <div className="filter__label">{calStrings.fitness}</div>
        </div> */}
        {/* ^^ Hardcoded Fitness tag for livestreams and onCampus classes */}
      </div>

      <div className="filters__title">
        <button
          className={`filters__button ${setFacultyActive}`}
          onClick={toggleFaculty}
        >
          {baseStrings.faculty}{" "}
          <GoPlus className={`icon ${setFacultyRotate}`} size="13px" />
        </button>
      </div>
      <div
        ref={facultyContent}
        style={{ maxHeight: `${setFacultyHeight}` }}
        className="filters__group"
      >
        {eventFaculty.map((filter, index) => {
          return (
            <div
              className="filter"
              key={index}
              onClick={() => handleFilter(filter.id, "faculty")}
            >
              <svg
                className={`filter__svg ${
                  appliedFilters["faculty"] &&
                  appliedFilters["faculty"].includes(filter.id)
                    ? "filter__svg--filled"
                    : ""
                }`}
              >
                <circle
                  className="filter__svg-border"
                  cx="11"
                  cy="11"
                  r="9.6"
                  fill="none"
                />
                <circle
                  className="filter__svg-fill"
                  cx="11"
                  cy="11"
                  r="9.3"
                  stroke="white"
                  fill="white"
                />
              </svg>
              <div className="filter__label">
                {preferEnglish ? filter.label_en : filter.label_fr}
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="filters__title">
        <button
          style={{ width: "82%" }}
          className={`filters__button ${setUniversitiesActive}`}
          onClick={toggleUniversities}
        >
          {baseStrings.universities}{" "}
          <GoPlus className={`icon ${setUniversitiesRotate}`} size="13px" />
        </button>
      </div> */}

      <div
        ref={universitiesContent}
        style={{ maxHeight: `${setUniversitiesHeight}` }}
        className="filters__group"
      >
        {baseStrings.universitiesFilters?.map((filter, index) => {
          return (
            <Filter
              key={index}
              type="universities"
              filter={filter}
              appliedFilters={appliedFilters}
              handleFilter={handleFilter}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventFilters;
