import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from "material-ui/RaisedButton";
import {Button} from "@material-ui/core";
import {Box, Typography} from "@material-ui/core";

export class Terms extends Component {

    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        return (
            <Box display = "flex" justifyContent = "center"  alignItems = "center" minHeight = "75vh" maxWidth="80vh">
                <MuiThemeProvider>
                    <div className = "Terms">
                        <strong>This supportive tool will recommend resources and services based on how you answer the following questions.</strong>
                        <br/>
                        <h3>To opt-out of de-identified data collection; please refer to our Privacy Policy.</h3>
                        <i>Please note that this tool is not meant for diagnosis and does not offer medical advice. If you are in need of mental health emergency support, mental health acute intervention, or are concerned about someone else's mental health i.e. harming themselves or others, please click here for emergency support of call 9-1-1.</i>
                    <br/>
                    <br/>
                    <Button variant="outlined" style={{borderRadius: 40, backgroundColor: "#ffffff", borderColor: "#8ad0de", borderWidth: 2, padding: "9px 40px", fontSize: "12px"}} onClick={this.continue}>I Understand!</Button>
                    <Button variant="outlined" style={{borderRadius: 40, backgroundColor: "#ffffff", borderColor: "#8ad0de", borderWidth: 2, padding: "9px 40px", fontSize: "12px"}} onClick={this.back}>Go Back</Button>
                    </div>
                </MuiThemeProvider>
            </Box>
        );
    }
}

const styles = {
    button: {
        margin: 15
    }
}
export default Terms