import React, { useEffect } from "react";
//Google Analytics for visitor record
import ReactGA from "react-ga";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

// Util
import CustomRoute from "util/customRouter";
import ScrollToTop from "util/scrollToTop";

import Alert from "components/Alert/Alert";

// Login
import Login from "pages/LoginSystem/Login";
import Register from "pages/LoginSystem/Register";
import ForgotPassword from "pages/LoginSystem/ForgotPassword";
import ResetPassword from "pages/LoginSystem/ResetPassword";
import EmailValidate from "pages/LoginSystem/EmailValidate";
// Home
import Home from "pages/Home/Home";
// Pages
import Quiz from "pages/MentalHealth/Quiz/Quiz";
import MentalHealth from "pages/MentalHealth/MentalHealth";
import Favourites from "pages/Favourites/Favourites";
import BrowseAllResources from "pages/MentalHealth/Browse";
import Mindfulness from "pages/MentalHealth/Mindfulness";
// Fitness
import Fitness from "pages/MyFitness/MyFitness";
import OnDemand from "./pages/MyFitness/OnDemand";
import OnCampus from "./pages/MyFitness/OnCampus";
import LiveStreams from "./pages/MyFitness/LiveStreams";

//AMS Clubs
import AMSClubs from "./pages/AMSClubs/AMSClubs";
import Clubs from "./pages/AMSClubs/AMSTabs/Clubs";

import { useSelector } from "react-redux";

// Style for Ant Design
// Ideally we could find a better solution for this as AntDesign inserts
// its theme into the CSS body element so effects everything
import "antd/dist/antd.css";

// French Data for Moment
import moment from "moment";
import "moment/locale/fr";

//Redux
import { loadUser } from "./redux/_actions/auth";
import ClubsResources from "./pages/AMSClubs/AMSTabs/ClubsResources";

const App = () => {
  ReactGA.initialize("UA-172079310-1");
  let lang = useSelector((state) => state.student.preferred_language);
  // Updates the moment library locale when language changes
  // Requires a reload of application so triggers the loading screen
  moment.locale(lang.toLowerCase());

  useEffect(() => {
    loadUser();
  });

  return (
    <>
      <Router>
        {/* Sets up Alert globally so it can be seen on any page */}
        <Alert />
        {/* When users jump to another page, it will scroll to the top */}
        <ScrollToTop />

        <Switch>
          {/* Login system */}
          <CustomRoute exact path={"/login"} page={<Login />} />
          <CustomRoute exact path={"/register"} page={<Register />} />
          <CustomRoute exact path={"/forgot-password"} page={<ForgotPassword />} />
          <CustomRoute exact path={"/reset/:id/:token"} page={<ResetPassword />} />
          <CustomRoute exact path={"/validate/:id/:token"} page={<EmailValidate />} />

          <CustomRoute exact path="/home" page={<Home />} />
          <CustomRoute exact path="/favourites" page={<Favourites />} />

          {/* Mental Health  */}
          <CustomRoute exact path="/mental-health" page={<MentalHealth />} />
          {/* <CustomRoute exact path="/mental-health/quiz" page={<Quiz />} /> */}
          <CustomRoute exact path="/mental-health/all-resource" page={<BrowseAllResources />} />
          <CustomRoute exact path="/mental-health/mindfulness-resource" page={<Mindfulness />} />

          {/* Fitness */}
          <CustomRoute exact path="/fitness" page={<Fitness />} />
          <CustomRoute exact path="/fitness/live-streams" page={<LiveStreams />} />
          <CustomRoute exact path="/fitness/on-campus" page={<OnCampus />} />
          <CustomRoute exact path="/fitness/on-demand" page={<OnDemand />} />

          {/*AMS Clubs */}
          <CustomRoute exact path="/ams-clubs" page={<AMSClubs />} />
          <CustomRoute exact path="/ams-clubs/all-clubs" page={<Clubs />} />
          <CustomRoute exact path="/ams-clubs/all-resources" page={<ClubsResources />} />
          <CustomRoute exact path="/ams-clubs/governance" page={<AMSClubs />} />

          {/* All incorrect routes go to Home */}
          <CustomRoute exact path="*" page={<Redirect to="/home" />} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
