import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import cpLogo from "imgs/navbar/ww-logo.png";
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";

import ReactGA from "react-ga";

import LanguageToggle from "components/LanguageToggle/LanguageToggle";

import { useInput } from "hooks";
import { sendAlert } from "redux/_actions/data";
import { useLanguage } from "language/hook";

//axios
import axiosRequest from "util/axiosConfig";
import { routes, loginErrors } from "util/constants";

const Register = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { preferEnglish, authStrings, utilStrings } = useLanguage();

  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const [name, bindName, resetName, nameError, setNameError] = useInput("");
  const [email, bindEmail, resetEmail, emailError, setEmailError] =
    useInput("");
  const [
    password,
    bindPassword,
    resetPassword,
    passwordError,
    setPasswordError,
  ] = useInput("");
  const [conPass, bindConPass, resetConPass, conPassError, setConPassError] =
    useInput("");
  const [year, bindYear, resetYear, yearError, setYearError] = useInput("");
  const [study, bindStudy, resetStudy, studyError, setStudyError] =
    useInput("");
  const [student, bindStudent, resetStudent, studentError, setStudentError] =
    useInput("");

  let history = useHistory();
  const redirectToLogin = () => {
    resetName();
    resetEmail();
    resetPassword();
    resetConPass();
    resetYear();
    resetStudy();
    resetStudent();
    history.push("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      preferred_name: name,
      email: email.toLowerCase(),
      password: password,
      con_password: conPass,
      study_year: year,
      preferred_language: preferEnglish ? "en" : "fr",
      type_of_study: study,
      type_of_student: student,
    };

    console.log(data);

    axiosRequest
      .post(routes.register, data)
      .then(() => {
        setLoading(false);
        setRegistered(true);
      })
      .catch((error) => {
        handleErrors(error);
        setLoading(false);
      });
  };

  const getEmailError = (error) => {
    if (error === loginErrors.mustNotBlank) return authStrings.mustNotBeBlank;
    else if (error === loginErrors.useSchoolEmail)
      return authStrings.useSchoolEmail;
    else if (error === loginErrors.emailTaken) return authStrings.emailTaken;
    else return authStrings.enterValidEmail;
  };

  const getPasswordError = (error) => {
    if (error === loginErrors.mustNotBlank) return authStrings.mustNotBeBlank;
    else if (error === loginErrors.passTooShort)
      return authStrings.passTooShort;
    else if (error === loginErrors.needEightChar)
      return authStrings.passTooShort;
    else if (error === loginErrors.passTooCommon)
      return authStrings.passwordCommon;
    else return "";
  };

  const handleErrors = (error) => {
    // Handle Errors Coming From Breaking Django Input Restrictions
    // These must be matched on the frontend so that we can provide the correct translation
    if (error.response.data) {
      let {
        preferred_name: _nameError,
        study_year: _yearError,
        type_of_study: _studyError,
        type_of_student: _studentError,
        email: _emailError,
        password: _passError,
        con_password: _conPassError,
      } = error.response.data;
      setNameError(_nameError ? authStrings.mustNotBeBlank : "");
      setYearError(_yearError ? authStrings.mustNotBeBlank : "");
      setStudentError(_studentError ? authStrings.mustNotBeBlank : "");
      setStudyError(_studyError ? authStrings.mustNotBeBlank : "");
      setEmailError(_emailError ? getEmailError(_emailError[0]) : "");
      setPasswordError(_passError ? getPasswordError(_passError[0]) : "");
      setConPassError(_conPassError ? authStrings.passwordsMustMatch : "");
      // If email was not sent by backend to register user
      if (error.response.data.email_fail) {
        sendAlert(utilStrings.serverError, "error");
      }
    } else sendAlert(utilStrings.serverError, "error");
  };

  return (
    <div className="onboarding">
      <LanguageToggle className="onboarding__lang-toggle button--blue" />
      <div className="onboarding__logo-container">
        <img src={cpLogo} alt="logo" />
        <img src={CP} alt="logo" />
      </div>
      {registered ? (
        <div className="onboarding__success">
          <span className="onboarding__title">
            {authStrings.youreAlmostThere(name)}
          </span>
          <span className="onboarding__info">{authStrings.thanksRegister}</span>
          <span className="onboarding__info">
            {authStrings.checkEmail(email)}
          </span>
          <button className="onboarding__link" onClick={redirectToLogin}>
            {authStrings.clickToLogin}
          </button>
        </div>
      ) : (
        <div className="onboarding__content">
          <span className="onboarding__title">
            {authStrings.pleaseRegister}
          </span>
          <form className="onboarding__form" onSubmit={handleSubmit}>
            <div className="onboarding__inputs">
              <span className="onboarding__label">{authStrings.name}</span>
              <input
                className={`onboarding__input ${
                  nameError ? "onboarding__input--error" : ""
                }`}
                type="text"
                placeholder={authStrings.yourPreferredName}
                {...bindName}
              />
              <span className="onboarding__error">{nameError}</span>

              <span className="onboarding__label">
                {authStrings.yearOfStudy}
              </span>
              <select
                className={`onboarding__input ${
                  yearError ? "onboarding__input--error" : ""
                }`}
                {...bindYear}
              >
                <option value="">{authStrings.selectYear}</option>
                <option value="1">{authStrings.firstYear}</option>
                <option value="2">{authStrings.secondYear}</option>
                <option value="3">{authStrings.thirdYear}</option>
                <option value="4">{authStrings.fourthYear}</option>
                <option value="5">{authStrings.fifthYear}</option>
              </select>
              <span className="onboarding__error">{yearError}</span>
              <span className="onboarding_label">
                {authStrings.type_study1}
              </span>
              <select
                className={`onboarding__input ${
                  studyError ? "onboarding__input--error" : ""
                }`}
                {...bindStudy}
              >
                <option value="Undergraduate">{authStrings.Study1}</option>
                <option value="Graduate">{authStrings.Study2}</option>
                <option value="Continuing Education">
                  {authStrings.Study3}
                </option>
                <option value="Professional Development">
                  {authStrings.Study4}
                </option>
              </select>
              <span className="onboarding__error">{yearError}</span>

              <span className="onboarding__label">
                {authStrings.type_student}
              </span>
              <select
                className={`onboarding__input ${
                  studentError ? "onboarding__input--error" : ""
                }`}
                {...bindStudent}
              >
                <option value="Domestic">{authStrings.Student1}</option>
                <option value="International">{authStrings.Student2}</option>
                <option value="Exchange ">{authStrings.Student3}</option>
              </select>
              <span className="onboarding__error">{studentError}</span>

              <span className="onboarding__label">{authStrings.email}</span>
              <input
                name="email"
                className={`onboarding__input ${
                  emailError ? "onboarding__input--error" : ""
                }`}
                type="text"
                placeholder={authStrings.enterEmail}
                {...bindEmail}
              />
              <span className="onboarding__error">{emailError}</span>

              <span className="onboarding__label">{authStrings.password}</span>
              <input
                name="password"
                className={`onboarding__input ${
                  passwordError ? "onboarding__input--error" : ""
                }`}
                type="password"
                placeholder={authStrings.mustBeLonger}
                {...bindPassword}
              />
              <span className="onboarding__error">{passwordError}</span>

              <span className="onboarding__label">
                {authStrings.conPassword}
              </span>
              <input
                className={`onboarding__input ${
                  conPassError ? "onboarding__input--error" : ""
                }`}
                type="password"
                placeholder={authStrings.conPassword}
                {...bindConPass}
              />
              <span className="onboarding__error">{conPassError}</span>
            </div>

            <div className="onboarding__redirect">
              {authStrings.alreadyAccount}&nbsp;&nbsp;
              <button type="button" onClick={redirectToLogin}>
                {authStrings.login}
              </button>
            </div>

            {loading ? (
              <div className="circle-button circle-button--loading">
                <Spinner color="#FFF" size="4rem" />
              </div>
            ) : (
              <input
                type="submit"
                className="circle-button"
                value={authStrings.register}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Register;
