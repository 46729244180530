import { sendAlert } from "redux/_actions/data";
import dedent from "dedent";

// redux
import store from "redux/_store";

export const handleCopy = (item, heading, dateString = "") => {
  let lang = store.getState().student.preferred_language;
  let preferEnglish = lang === "en";
  let {
    title,
    title_en,
    title_fr,
    description,
    description_en,
    description_fr,
    link,
    event_link,
    host_link,
    author_or_note,
  } = item;
  // IMPORTANT: DO NOT CHANGE HOW THIS FILE IS INDENTED AS IT WILL AFFECT HOW IT
  // APPEARS ON THE SEND TO FRIEND TEXT THAT IS COPIED TO CLIPBOARD
  let finalText = dedent(`${heading}
                            ${!title ? (preferEnglish ? title_en : title_fr) : title}
                            ${!description ? (preferEnglish ? description_en : description_fr) : description}${
    author_or_note ? `\n${author_or_note}` : ""
  } 
  
                            ${
                              link
                                ? preferEnglish
                                  ? `Check out the link here: ${link}`
                                  : `Consultez le lien ici: ${link}`
                                : ""
                            }\n${
    event_link ? (preferEnglish ? `View the event here: ${event_link}` : `Voir l’événement ici: ${event_link}`) : ""
  }\n${host_link ? (preferEnglish ? `Hosted here: ${host_link}` : `Hébergé ici: ${host_link}`) : ""}
                            ${dateString}`);
  copyDetailsToClipboard(finalText);
};

const copyDetailsToClipboard = (text) => {
  let lang = store.getState().student.preferred_language;
  let preferEnglish = lang === "en";

  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  sendAlert(preferEnglish ? "Copied to Clipboard" : "Copié dans le presse-papier");
};
