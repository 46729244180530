import { combineReducers } from "redux";
import auth from "./auth.js";
import data from "./data.js";
import student from "./student.js";

// plan to add other reducer later, so it uses comnbinReducers
export default combineReducers({
  auth,
  data,
  student,
});
