import React from "react";

import cpLogo from "imgs/navbar/ww-logo.png";

import "./Profile.scss";

import { useInput } from "hooks";
import { useSelector, useDispatch } from "react-redux";
import { updateStudent } from "redux/_actions/students";
import { FOCUS_STUDENT } from "redux/types";
import { useLanguage } from "language/hook";

const Profile = () => {
  const student = useSelector((state) => state.student);

  const [preferred_name, bindName] = useInput(student.preferred_name);
  console.log("STUDENT: ", student);
  const [study_year, bindYear] = useInput(student.study_year);
  const [student_type, bindStudentType] = useInput(student.type_of_student);
  const [study_type, bindStudyType] = useInput(student.type_of_study);
  const { authStrings } = useLanguage();

  const dispatch = useDispatch();

  const handleUpdate = () => {
    updateStudent({
      preferred_name,
      study_year,
      preferred_language: student.preferred_language,
      type_of_student: student_type,
      type_of_study: study_type,
    });
  };

  const handleFocus = () => {
    dispatch({
      type: FOCUS_STUDENT,
    });
  };

  return (
    <div className="profile">
      <img className="profile__logo" src={cpLogo} alt={"Wellness World Logo"} />

      <div className="profile__inputs">
        <div>{authStrings.university}</div>
        <span>{student.university.name}</span>

        <div>{authStrings.email}</div>
        <span>{student.email}</span>

        <div>{authStrings.preferredName}</div>
        <input {...bindName} onFocus={handleFocus} />

        <div>{authStrings.yearOfStudy}</div>
        <select {...bindYear} onFocus={handleFocus}>
          <option value="1">{authStrings.firstYear}</option>
          <option value="2">{authStrings.secondYear}</option>
          <option value="3">{authStrings.thirdYear}</option>
          <option value="4">{authStrings.fourthYear}</option>
          <option value="5">{authStrings.fifthYear}</option>
        </select>

        <div>{authStrings.type_study1}</div>
        <select {...bindStudyType} onFocus={handleFocus}>
          <option value="Undergraduate">{authStrings.Study1}</option>
          <option value="Graduate">{authStrings.Study2}</option>
          <option value="Continuing Education">{authStrings.Study3}</option>
          <option value="Professional Development">{authStrings.Study4}</option>
        </select>

        <div>{authStrings.type_student}</div>
        <select {...bindStudentType} onFocus={handleFocus}>
          <option>Not Selected</option>
          <option value="Domestic">{authStrings.Student1}</option>
          <option value="International">{authStrings.Student2}</option>
          <option value="Exchange">{authStrings.Student3}</option>
        </select>
      </div>

      {student.updating ? (
        <button className="profile__button profile__button--saving">
          {authStrings.saving}
        </button>
      ) : student.updated ? (
        <button className="profile__button profile__button--saved">
          {authStrings.saved}
        </button>
      ) : (
        <button className="profile__button" onClick={handleUpdate}>
          {authStrings.save}
        </button>
      )}
    </div>
  );
};

export default Profile;
