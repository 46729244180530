import { useState } from "react";

export const useInput = (initValue) => {
  const [input, setInput] = useState({ value: initValue, error: "" });

  const reset = () => {
    setInput({ value: initValue, error: "" });
  };

  const setError = (error) => {
    setInput({ ...input, error: error });
  };

  const bind = {
    value: input.value,
    onChange: (e) => {
      // Updates the input.value with the value in the input field
      setInput({ ...input, value: e.target.value });
    },
    onFocus: () => {
      // Removes error text when the field is focused
      setInput({ ...input, error: "" });
    },
    onBlur: (e) => {
      // Trim whitespace from beginning and end of input
      setInput({ ...input, value: e.target.value.trim() });
    },
  };

  return [input.value, bind, reset, input.error, setError];
};
