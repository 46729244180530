import React, { useEffect } from "react";

const Filter = ({ type, filter, appliedFilters, handleFilter, selected }) => {
  useEffect(() => {
    if (selected) {
      handleFilter(filter.value, type);
    }
  }, [selected]);
  return (
    <div className="filter" onClick={() => handleFilter(filter.value, type)}>
      <svg
        className={`filter__svg ${
          appliedFilters[type] && appliedFilters[type].includes(filter.value)
            ? "filter__svg--filled"
            : ""
        }`}
      >
        <circle
          className="filter__svg-border"
          cx="11"
          cy="11"
          r="9.6"
          fill="none"
        />
        <circle
          className="filter__svg-fill"
          cx="11"
          cy="11"
          r="9.3"
          stroke="white"
          fill="white"
        />
      </svg>
      <div className="filter__label">{filter.label}</div>
    </div>
  );
};

export default Filter;
