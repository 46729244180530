import React from "react";
import { Link } from "react-router-dom";

import group from "imgs/my-fitness/working outtt.png";
import youtube from "imgs/my-fitness/youtube.png";
import ondemand from "imgs/my-fitness/yoga mat.png";
import gym from "imgs/my-fitness/kettlebell.png";

import ReactGA from "react-ga";

import { useLanguage } from "language/hook";

import "./MyFitness.scss";
import {useSelector} from "react-redux";

const MyFitness = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { baseStrings, bannerStrings } = useLanguage();
  const universityName = useSelector(state => state.student.university.name);

  const resource = [
    {
      title: baseStrings.liveStreamClasses,
      description: baseStrings.liveStreamHeading,
      icon: youtube,
      link: "/fitness/live-streams",
    },
    {
      title: baseStrings.onDemandClasses,
      description: baseStrings.onDemandHeading,
      icon: ondemand,
      link: "/fitness/on-demand",
    },
    {
      title: baseStrings.onCampusClasses,
      description: baseStrings.onCampusHeading,
      icon: group,
      link: "/fitness/on-campus",
    },
  ];

  if (universityName === "Queens") {
    return (
      <>
        <div className="fitness">
          <div className="fitness__title heading-primary">{baseStrings.fitnessHeading}</div>
          <div className="fitness__blocks">
            {resource.map((resource, index) => (
              <Link className="fitness-block" to={resource.link} key={index}>
                <div className="fitness-block__title heading-secondary">{resource.title}</div>
                <div className="fitness-block__img">
                  <img src={resource.icon} alt={resource.title} />
                </div>
                <span className="fitness-block__desc text-primary">{resource.description}</span>
              </Link>
            ))}
            <a className="fitness-block" href={baseStrings.gymLinkQueens} target="_blank" rel="noopener noreferrer">
              <div className="fitness-block__title heading-secondary">{baseStrings.gymTime}</div>
              <div className="fitness-block__img">
                <img src={gym} alt="gym icon" />
              </div>
              <span className="fitness-block__desc text-primary">{baseStrings.gymTimeHeading}</span>
            </a>
          </div>
        </div>
        <div className="fitness-banner">
          <span className="text-banner">{bannerStrings.fitnessFooter}</span>
        </div>
      </>
    );
  }else
    return (
      <>
        <div className="fitness">
          <div className="fitness__title heading-primary">{baseStrings.fitnessHeading}</div>
          <div className="fitness__blocks">
            {resource.map((resource, index) => (
              <Link className="fitness-block" to={resource.link} key={index}>
                <div className="fitness-block__title heading-secondary">{resource.title}</div>
                <div className="fitness-block__img">
                  <img src={resource.icon} alt={resource.title} />
                </div>
                <span className="fitness-block__desc text-primary">{resource.description}</span>
              </Link>
            ))}
            <a className="fitness-block" href={baseStrings.gymLink} target="_blank" rel="noopener noreferrer">
              <div className="fitness-block__title heading-secondary">{baseStrings.gymTime}</div>
              <div className="fitness-block__img">
                <img src={gym} alt="gym icon" />
              </div>
              <span className="fitness-block__desc text-primary">{baseStrings.gymTimeHeading}</span>
            </a>
          </div>
        </div>
        <div className="fitness-banner">
          <span className="text-banner">{bannerStrings.fitnessFooter}</span>
        </div>
      </>
    );
  };

export default MyFitness;
