const getCalendarTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        prev: "Prev",
        next: "Next",
        today: "Today",
        week: "Week",
        month: "Month",
        fitness: "Fitness",
        myCampusCalendar: "My Campus Calendar",
      }
    : {
        prev: "Préc.",
        next: "Suiv.",
        today: "Auj.",
        week: "Mois",
        month: "Semaine",
        fitness: "Aptitude",
        myCampusCalendar: "Le calendrier de mon campus",
      };
};

export default getCalendarTranslations;
