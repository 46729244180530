import React, { useState, useEffect } from "react";
import CustomModal from "components/CustomModal/CustomModal";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";

import "./Calendar.scss";

import { useLanguage } from "language/hook";
import axiosRequest from "util/axiosConfig";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import Event from "components/Items/Event";
import OnCampusClass from "components/Items/OnCampusClass";
import LivestreamClass from "components/Items/LiveStreamClass";
import EventFilters from "../Filters/EventFilters";
import { formatEvents, formatOnlyEvents } from "util/dataHelpers";
import { useSelector } from "react-redux";

import moment from "moment";
import { routes } from "util/constants";

// FullCalendar Docs: https://fullcalendar.io/docs
const Calendar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedOnCampus, setSelectedOnCampus] = useState(null);
  const [selectedLivestream, setSelectedLivestream] = useState(null);

  // CHANGE: Got rid of loading variable in these for simplicity
  const [livestreams, setLivestream] = useState([]);
  const [onCampusClasses, setOnCampusClasses] = useState([]);
  const [events, setEvents] = useState([]); //load events into here

  const lang = useSelector((state) => state.student.preferred_language);
  const onCampusFavourites = useSelector((state) => state.student.fav_oncampus);
  const livestreamFavourites = useSelector(
    (state) => state.student.fav_livestream
  );
  const universityId = useSelector((state) => state.student.university.id);

  const { calStrings } = useLanguage();

  useEffect(() => {
    let isCancelled = false;
    // Checks if fitness is the only tag applied and if so ignore all other events
    if (
      appliedFilters.tags &&
      appliedFilters.tags.length === 1 &&
      appliedFilters.tags[0] === "fitness"
    ) {
      setEvents([]);
    } else {
      // If other tags applied sort but ignore the fitness filter
      let filteredUrl = "";
      for (let filterType in appliedFilters) {
        for (let filter of appliedFilters[filterType]) {
          if (filter !== "fitness") filteredUrl += `${filterType}=${filter}&`;
        }
      }
      //get data from backend(admin) for main calendar
      async function fetchData() {
        await axiosRequest
          .get(routes.events(filteredUrl))
          .then((response) => {
            console.log(response, "RESPONSE");
            if (!isCancelled) {
              let formattedEvents = formatOnlyEvents(response.data, "event"); //format the events in terms of date/start-endtime

              // filter events by university
              let filteredEvents = formattedEvents.filter((event) => {
                return event.university.id === universityId;
              });

              setEvents(filteredEvents); //loads info into event.data array
            }
          })
          .catch((error) => console.log(error));
      }
      fetchData();
    }

    return () => (isCancelled = true);
  }, [appliedFilters]);

  //get data from oncampus for main calendar
  useEffect(() => {
    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.onCampusAll)
        .then((response) => {
          if (!isCancelled) {
            let formattedOnCampusClasses = formatEvents(
              response.data,
              "onCampus"
            ); //format onCampus classes in terms of date/start-end time

            let filteredOnCampusClasses = formattedOnCampusClasses.filter(
              (onCampusClass) => {
                return onCampusClass.university === universityId;
              }
            );

            setOnCampusClasses(filteredOnCampusClasses); //do something similar as setEvents
          }
        })
        .catch((error) => console.log(error));
    }
    fetchData();
    return () => (isCancelled = true);
  }, []);

  //get data from backend(livestream) for main calendar
  useEffect(() => {
    let isCancelled = false;
    async function fetchData() {
      await axiosRequest
        .get(routes.livestreamAll)
        .then((response) => {
          if (!isCancelled) {
            let formattedLivestreamClasses = formatEvents(
              response.data,
              "livestream"
            ); //format onCampus classes in terms of date/start-end time

            let filteredLiveStreamClasses = formattedLivestreamClasses.filter(
              (liveStreamClass) => {
                return liveStreamClass.university === universityId;
              }
            );

            setLivestream(filteredLiveStreamClasses); //do something similar as setEvents
          }
        })
        .catch((error) => console.log(error));
    }
    fetchData();
    return () => (isCancelled = true);
  }, []);

  const handleFilter = (newId, key) => {
    let newAppliedFilters = { ...appliedFilters };
    if (newAppliedFilters[key]) {
      if (newAppliedFilters[key].includes(newId)) {
        // Remove filters id if it was applied when selected
        newAppliedFilters[key] = newAppliedFilters[key].filter(
          (id) => id !== newId
        );
      } else {
        // Add filters id if it was not applied when selected
        newAppliedFilters[key] = [...newAppliedFilters[key], newId];
      }
    } else {
      // If first of that type of filter add that type to appliedFilters object
      newAppliedFilters[key] = [newId];
    }
    setAppliedFilters(newAppliedFilters);
  };

  const handleEventClick = (event) => {
    //event is the click event, not the actual "event"
    let { publicId } = event.event._def;
    let extendedProps = event.event._def.extendedProps;
    let type = extendedProps.type;
    let date = moment(event.event.start).format("YYYY-MM-DD");
    let start_time = moment(event.event.start).format("HH:mm:ss");
    let end_time = moment(event.event.end).format("HH:mm:ss");
    let eventInfo = {
      ...extendedProps,
      id: publicId,
      start_time,
      end_time,
      date,
    };

    if (type === "event") setSelectedEvent(eventInfo);
    else if (type === "livestream") setSelectedLivestream(eventInfo);
    else if (type === "onCampus") setSelectedOnCampus(eventInfo);
  };

  // CHANGE: eventsForCalendar will combine events, livestreams and onCampus classes for FullCalendar
  let eventsForCalendar = [...events, ...livestreams, ...onCampusClasses]; // Events are always added as they are filtered in above useEffect

  if (appliedFilters.location && appliedFilters.location.includes("oncampus")) {
    if (
      appliedFilters.tags &&
      appliedFilters.tags.length > 0 &&
      !appliedFilters.tags.includes("fitness")
    ) {
      //check if tags[] does not include fitness
      eventsForCalendar = [...events];
    } else {
      eventsForCalendar = [...events, ...onCampusClasses];
    }
  } else if (
    appliedFilters.location &&
    appliedFilters.location.includes("online")
  ) {
    if (
      appliedFilters.tags &&
      appliedFilters.tags.length > 0 &&
      !appliedFilters.tags.includes("fitness")
    ) {
      eventsForCalendar = [...events];
    } else {
      eventsForCalendar = [...events, ...livestreams];
    }
  } else if (
    appliedFilters.location &&
    appliedFilters.location.includes("offcampus")
  )
    eventsForCalendar = [...events]; //off campus filter option
  //how to make it so that when oncampus and art(or anything else that isn't fitness) is chosen, the oncampusEvents and livestream dont show up, cuz oncampusEvents and livestreamevents are fitness stuff.

  // Handle when to add livestreams and onCampusClasses to the calendar:
  // ie. eventsForCalendar = [...eventsForCalendar, livestreams, onCampusClasses] would add them to calendar
  // Decide based on selected filters which can be found in appliedFilters and are currently logged to browser console from below
  // Fitness tag will simply be under the string "fitness"
  console.log("eventsForCalender", eventsForCalendar);
  return (
    <div className="calendar">
      <div className="calendar__title heading-primary">
        {calStrings.myCampusCalendar}
      </div>

      <div className="calendar__filters">
        <EventFilters
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
          besideCalendar
        />
      </div>

      <button
        className="calendar__filter-button button--blue"
        onClick={() => setDrawerOpen(true)}
      >
        Filters
      </button>

      <CustomDrawer
        visible={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
      >
        <EventFilters
          inDrawer
          appliedFilters={appliedFilters}
          handleFilter={handleFilter}
        />
      </CustomDrawer>

      <div className="calendar__display">
        <FullCalendar
          datesSet={function (view) {
            console.log("view: ", view);
            let calendarView = view.view.type;
            localStorage.setItem("initial-view", calendarView);
          }}
          dragScroll={true}
          locale={lang}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek",
          }}
          buttonIcons={false}
          buttonText={{
            today: calStrings.today,
            week: calStrings.week,
            month: calStrings.month,
            prev: calStrings.prev,
            next: calStrings.next,
          }}
          initialView={
            localStorage.getItem("initial-view")
              ? localStorage.getItem("initial-view")
              : "dayGridMonth"
          }
          eventClick={handleEventClick}
          events={eventsForCalendar} //displays events onto the calendar
          dayMaxEvents={true}
          themeSystem="standard"
          fixedWeekCount={false}
          showNonCurrentDates={false}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: true,
            meridiem: "short",
          }}
        />
      </div>

      {selectedEvent && (
        <CustomModal handleClose={() => setSelectedEvent(null)}>
          <Event event={selectedEvent} key={selectedEvent.title} isPopUp />
        </CustomModal>
      )}
      {selectedOnCampus && (
        <CustomModal handleClose={() => setSelectedOnCampus(null)}>
          <OnCampusClass
            content={selectedOnCampus}
            isFavourite={onCampusFavourites
              .map((item) => item.id)
              .includes(selectedOnCampus.id)}
            key={selectedOnCampus.id}
            isPopUp
          />
        </CustomModal>
      )}

      {selectedLivestream && (
        <CustomModal handleClose={() => setSelectedLivestream(null)}>
          <LivestreamClass
            content={selectedLivestream}
            isFavourite={livestreamFavourites
              .map((item) => item.id)
              .includes(selectedLivestream.id)}
            key={selectedLivestream.id}
            isPopUp
          />
        </CustomModal>
      )}
    </div>
  );
};

export default Calendar;
