import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

//Google Analytic
import ReactGA from "react-ga";
//Image
import wwLogo from "imgs/navbar/ww-logo.png";
import WW from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";
import EmbeddedVideo from "components/EmbeddedVideo/EmbeddedVideo";

//Redux
import { login } from "redux/_actions/auth";

import LanguageToggle from "components/LanguageToggle/LanguageToggle";
import { useSelector, useDispatch } from "react-redux";
import { LOGIN_RESET } from "redux/types";
import { useLanguage } from "language/hook";
import { useInput } from "hooks";

const Login = () => {
	//Google Analystics
	ReactGA.pageview(window.location.pathname + window.location.search);

	const auth = useSelector((state) => state.auth);
	const { isAuthenticated, loginLoading, loginError, loginAttempts } = auth;
	const dispatch = useDispatch();
	const { authStrings } = useLanguage();
	const [email, bindEmail, resetEmail] = useInput("");
	const [password, bindPassword, resetPassword] = useInput("");

	const removeErrorHighlight = () => {
		dispatch({
			type: LOGIN_RESET,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		login(email.toLowerCase(), password);
	};

	// Check if authenticated
	let history = useHistory();
	useEffect(() => {
		if (isAuthenticated) {
			history.push("/home");
		}
	}, [isAuthenticated, history]);

	const redirect = (link) => {
		resetEmail();
		resetPassword();
		history.push(link);
	};

	return (
		<div className="onboarding">
			<LanguageToggle className="onboarding__lang-toggle button--blue" />

			<div className="onboarding__logo-container">
				<img src={wwLogo} alt={"logo"} />
				<img src={WW} alt={"logo"} />
				<h1>{authStrings.yourHolisticPlatform}</h1>
				{/*<div>*/}
				{/*	<EmbeddedVideo embedId="2fLnb6u5rU8" />*/}
				{/*</div>*/}
			</div>

			<div className="onboarding__content">
				<span className="onboarding__title">{authStrings.welcomeBack}</span>
				<form
					className="onboarding__form"
					onSubmit={handleSubmit}
					onFocus={removeErrorHighlight}
				>
					<div className="onboarding__inputs onboarding__inputs">
						<label className="onboarding__label onboarding__label">
							{authStrings.email}
						</label>
						<input
							name="email"
							type="text"
							className={`onboarding__input onboarding__input--login ${
								loginError ? "onboarding__input--error" : ""
							}`}
							placeholder={authStrings.enterEmail}
							{...bindEmail}
						/>
						<label className="onboarding__label">{authStrings.password}</label>
						<input
							name="password"
							type="password"
							className={`onboarding__input ${
								loginError ? "onboarding__input--error" : ""
							}`}
							placeholder={authStrings.enterPassword}
							{...bindPassword}
						/>

						<span className="onboarding__error">
							{loginError
								? loginAttempts > 3
									? authStrings.checkIfVerified
									: authStrings.loginIncorrect
								: ""}
						</span>
					</div>
					<button
						type="button"
						className="onboarding__forgot"
						onClick={() => redirect("/forgot-password")}
					>
						{authStrings.forgotPassword}
					</button>
					<div className="onboarding__redirect">
						{authStrings.notYetSignedUp}&nbsp;&nbsp;
						<button type="button" onClick={() => redirect("/register")}>
							{authStrings.register}
						</button>
					</div>

					{loginLoading ? (
						<div className="circle-button circle-button--loading">
							<Spinner color="#FFF" size="4rem" />
						</div>
					) : (
						<input
							type="submit"
							className="circle-button"
							value={authStrings.enter}
						/>
					)}
				</form>
			</div>
		</div>
	);
};

export default Login;