import React from "react";

import { useLanguage } from "language/hook";
import { useSelector } from "react-redux";

const OnDemandFilters = ({ appliedFilters, handleFilter, inDrawer }) => {
  const { preferEnglish, baseStrings } = useLanguage();
  const filters = useSelector((state) => state.data.onDemandTags);

  return (
    <div className={`filters ${inDrawer ? "filters--drawer" : ""}`}>
      <div className="filters__group">
        <div className="filters__title">{baseStrings.tags}</div>
        {filters.map((filter, index) => {
          return (
            <div className="filter" key={index} onClick={() => handleFilter(filter.id)}>
              <svg className={`filter__svg ${appliedFilters.includes(filter.id) ? "filter__svg--filled" : ""}`}>
                <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />
                <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />
              </svg>
              <div className="filter__label">{preferEnglish ? filter.label_en : filter.label_fr}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OnDemandFilters;
