import React from "react";

import { setLanguage } from "redux/_actions/students";
import { Tooltip } from "antd";
import { useLanguage } from "language/hook";

import "./LanguageToggle.scss";

const LanguageToggle = ({ className }) => {
  const { preferEnglish } = useLanguage();

  return (
    <Tooltip placement={"bottomLeft"} title={preferEnglish ? "Passer au français" : "Switch to English"}>
      <button className={`language-toggle ${className}`} onClick={() => setLanguage(preferEnglish ? "fr" : "en")}>
        {preferEnglish ? "FR" : "EN"}
      </button>
    </Tooltip>
  );
};

export default LanguageToggle;
