import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Paper, Box, Container} from "@material-ui/core";
import {useLanguage} from "../../../../language/hook";

export class Success extends Component {

    render() {
        return (
            <MuiThemeProvider>
                <Box display = "flex" justifyContent = "center" alignItems = "center">
                    <div className="list-page__title"> Recommendations Page </div>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                        <Paper width="100%" height="100%" elevation = {24} backgroundColor="blue">
                            <h2>API Call</h2>
                        </Paper>
                    </Box>
                </Box>
            </MuiThemeProvider>
        );
    }
}

const styles = {
    button: {
        margin: 15
    }
}
export default Success