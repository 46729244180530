import React from "react";
import { Link } from "react-router-dom";

import backgroundBlue from "imgs/mental-health/background-blue.svg";
import amsLogo from "imgs/ams-clubs/Clubs-Logo.png";
import amsRLogo from "imgs/ams-clubs/clubsResources.png";
import articles from "imgs/mental-health/articles.png";
import scale from "imgs/mental-health/scale.png";

import cpLogo from "imgs/navbar/ww-logo.png";

import "./AMSClubs.scss";

import ReactGA from "react-ga";
import { useLanguage } from "language/hook";

const AMSClubs = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { baseStrings, bannerStrings } = useLanguage();

  const tabs = [
    {
      title: baseStrings.browseAllClubs,
      description: baseStrings.browseAllClubsDescription,
      button: baseStrings.viewAll,
      back: backgroundBlue,
      icon: amsLogo,
      link: "ams-clubs/all-clubs",
    },
    {
      title: baseStrings.browseAllClubsResources,
      description: baseStrings.browseAllClubsResourcesDescription,
      button: baseStrings.viewAll,
      icon: amsRLogo,
      link: "ams-clubs/all-resources",
    },
    // {
    //   title: baseStrings.governance,
    //   description: baseStrings.mindfulnessHeading,
    //   button: baseStrings.viewAll,
    //   back: backgroundGrey,
    //   icon: scale,
    //   link: "ams-clubs/governance",
    // },
  ];

  return (
    <div className="mental-health">
      {tabs.map((tab, index) => (
        <div className="tab-container" style={{ backgroundImage: `url(${tab.back})` }} key={index}>
          <div className="tab__info">
            <img className="tab__img" src={tab.icon} alt={tab.title} />
            <div className="tab__title heading-secondary">{tab.title}</div>
            <div className="tab__description">{tab.description}</div>
          </div>
          {tab.linksOut ? (
            <a href={tab.link} target="_blank" rel="noopener noreferrer">
              <button className="tab__button">{tab.button}</button>
            </a>
          ) : (
            <Link to={tab.link}>
              <button className="tab__button">{tab.button}</button>
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

export default AMSClubs;
