// AUTH
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// STUDENT
export const SET_STUDENT = "SET_STUDENT";
export const RESET_STUDENT = "RESET_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STUDENT_START = "UPDATE_STUDENT_START";
export const FOCUS_STUDENT = "FOCUS_STUDENT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_FAVOURITES = "SET_FAVOURITES";

// DATA
export const SET_LINKS = "SET_LINKS";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_EVENTS_TAGS = "SET_EVENTS_TAGS";
export const SET_EVENTS_FACULTY = "SET_EVENTS_FACULTY";
export const SET_ONDEMAND_TAGS = "SET_ONDEMAND_TAGS";
export const SET_RESOURCE_FILTERS = "SET_RESOURCE_FILTERS";
export const SET_CLUBS_FILTERS = "SET_CLUBS_FILTERS";
export const SET_CLUBS_TYPES = "SET_CLUBS_TYPES";
export const SET_CLUBS_FACULTY = "SET_CLUBS_FACULTY";

export const SET_CLUBS_RESOURCES_FILTERS = "SET_CLUBS_RESOURCES_FILTERS";

export const SET_EVENTS_TODAY = "SET_EVENTS_TODAY";

export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT_TIMER = "CLEAR_ALERT_TIMER";
export const HIDE_ALERT = "HIDE_ALERT";
